/** @format */

//import { SearchOutlined } from '@ant-design/icons';
import {  Col, Form, Row } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
//import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Service } from './service'
import CustomModal from '../../../../../v2-components/Antd/Modal';
import { setGlobal, useGlobal } from 'reactn';
import { FindIpAdapter } from '../../../../../_config/adapters/ip/ip-location.http';
import { ResponseIpAdapter } from '../../../../../_config/adapters/ip/ip-response.adapter';
import { LocalStorage } from '../../../../../_config/adapters/local-storage/local-storage.constant';
import { StorageSetItemAdapter } from '../../../../../_config/adapters/local-storage/storage-set-item.adapter';
// import CustomButton from '../../../../../v2-components/Antd/Button';
// import { ReactComponent as IconLocation } from '../../../../../v2-config/icons-svg/location.svg';
import { GlobalConsumer } from '../../../Context/Global';
// import { AddressSearch } from '../../../Hooks/Functions/AddressSearch';
import { AddressSetLocation } from '../../../Hooks/Functions/AddressSetLocation';
import { PharmacySetLocation } from '../../../Hooks/Functions/PharmacySetLocation';
import Images from '../../../Images';
// import InputField from '../../../Input';
// import MapAddress from './components/MapAddress';
import { handleAddressContainer } from './handle/address-container.handle';
import { handleLocationImage } from './handle/location-image.handle';
// import { handleShortName } from './handle/short-name.handle';
import { CustomNotification } from '../../../../../v2-components/Antd/Notification';
import { NOTIFICATION } from '../../../../../v2-components/Antd/Notification/constant';
import GoogleMap from './components/GoogleMap';
import { geocodeAddress } from './utils/geocodeAddress'
import { AddressSteps } from './components/AddressSteps'

import './style.scss';


	export default function AddressComponent() {
		const { isModalAddress, setModalAddress, isLocation, setLocation } = GlobalConsumer();
		const history = useHistory();
		const location = useLocation();
		const [currentStep, setCurrentStep] = useState(0);
		const [isLoadingButton, setLoadingButton] = useState(false);
		const [isAddressDisplay, setAddressDisplay] = useGlobal('address');
		const [isUserInfo] = useGlobal("userMainInfo");
		const isUserToken = useMemo(() => isUserInfo?.token || null, [isUserInfo]);
		const [, setNearPharmacy] = useGlobal('mapPharmacy');
		const [attempts, setAttempts] = useState(true);
		const [regionOptions, setRegionOptions] = useState([]);
		const [communeOptions, setCommuneOptions] = useState([]);
		const [form] = Form.useForm();
		const [selectedRegion, setSelectedRegion] = useState(undefined); 
		const [selectedCommune, setSelectedCommune] = useState(undefined);
		const [directionValue, setDirectionValue] = useState('');
		const [coordinates, setCoordinates] = useState(null);
		const [newAddress, setNewAddress] = useState(null)
		const [disableContinueButton, setDisableContinueButton] = useState(true);
		const [latlng, setLatlng] = useState(undefined); 
		const [saveAddressChecked, setSaveAddressChecked] = useState(false);
		const [aliasValue, setAliasValue] = useState('');
		const [isAddressSaved, setAddressSaved] = useState(false)
		const [userAddressList, setUserAddressList] = useState([]);
		const [formattedAddressOptions, setFormattedAddressOptions] = useState([]);
		const [selectedUserAlias, setSelectedUserAlias] = useState(null);
		const [isAddressListItemSelected, setAddressListItemSelected] = useState(false)
		const [previousLocation, setPreviousLocation] = useState(null);
	
	
		const handleCloseModal = () => {
			if(currentStep < 2 && isUserToken) {
				const [region, commune, direction] = isLocation.formFields.split(',').map(item => item.trim());
			
				setSelectedRegion(region);
				setSelectedCommune(commune);
				setDirectionValue(direction);
				
				form.setFieldsValue({ 
					region,
					commune, 
					direction,
					addressList: undefined
				});
			}
			setModalAddress(false);
			setCurrentStep(0);
			const lastUsedAddress = userAddressList.find(item => item.formFields === isLocation.formFields);
			if (lastUsedAddress) {
				setSelectedUserAlias(lastUsedAddress.alias);
				form.setFieldsValue({ addressList: lastUsedAddress.alias });
			} 
		}; 
	
		const handleGetAddress = async () => {
			setLoadingButton(true);
			const region = form.getFieldValue('region') || '';
			const commune = form.getFieldValue('commune') || '';
			const direction = form.getFieldValue('direction') || '';
			const addressString = region + ', ' + commune + ', ' + direction;
			setLatlng(addressString);
	
			try {
				const responseCoordinates = await geocodeAddress(addressString);
			
				const address = await AddressSetLocation(addressString);
				
				if (address && address.lat && address.lng && responseCoordinates) {
					setCoordinates(responseCoordinates);
					setNewAddress(address);
					handleNextStep();
				} else {
					throw new Error('No se pudo obtener la dirección');
				}
			} catch (error) {
				setDisableContinueButton(true);
				console.log('error:', error);
				CustomNotification({ 
					type: 'error', 
					message: null, 
					description: 'La dirección que estás intentando buscar no existe' 
	
				});
			} finally {
				setLoadingButton(false);
			}
		};
	
		const handleConfirmAddress = async () => {
			if (saveAddressChecked && !aliasValue) {
				CustomNotification({
					type: NOTIFICATION.TYPE.ERROR,
					message: NOTIFICATION.MESSAGE.ERROR,
					description: 'Por favor, ingresa una etiqueta para la dirección'
				});
				return;
			}
	
			setLoadingButton(true);
			
			// Save the previous address
			setPreviousLocation(isLocation);
			
			await PharmacySetLocation(newAddress).then((response) => {
				setNearPharmacy(response);
			}); 
			
			await saveNewAddress()
	
			setLocation(newAddress);
	
			setGlobal({ collapseMapPharmacies: [] });
			setGlobal(() => ({ userAddressList: newAddress }));
			
			setLoadingButton(false);
			handleNextStep()
		}
	
		const saveNewAddress = async () => {
			if (isUserInfo && saveAddressChecked) {
				const region = form.getFieldValue('region') || '';
				const commune = form.getFieldValue('commune') || '';
				const direction = form.getFieldValue('direction') || '';
				const formFields = `${region}, ${commune}, ${direction}`;
				
				const newLocation = await AddressSetLocation(formFields, aliasValue);
				setLocation(newLocation);
						
				const newAddressToSave = {
					alias: aliasValue,
					location: newLocation.location,
					formFields: formFields
				};
	
				const updatedUserAddressList = [...userAddressList, newAddressToSave];
				setUserAddressList(updatedUserAddressList);
	
				return new Service()
					.saveAddress(isUserToken, updatedUserAddressList)
					.then(response => {
						console.log("address saved successfully", response);
						setAddressSaved(true);
						return true;
					})
					.catch(e => {
						console.log("saveAddress error", e);
						setAddressSaved(false);
						return false; 
					});
			}
			return true; 
		};
	
		const handleDeleteAddress = async () => {
			if (!userAddressList || userAddressList.length === 0) {
				console.error("Empty address list");
				return false;
			}
	
			try {
				// Restore the previous address
				if (previousLocation) {
					await PharmacySetLocation(previousLocation).then((response) => {
						setNearPharmacy(response);
					});
					
					setLocation(previousLocation);
					setGlobal({ collapseMapPharmacies: [] });
					
					// Update localStorage
					localStorage.setItem('locationStore', JSON.stringify(previousLocation));
				}
				if (saveAddressChecked){
					const updatedAddressList = [...userAddressList];
					updatedAddressList.pop();
		
					return new Service()
						.saveAddress(isUserToken, updatedAddressList)
						.then(response => {
							setUserAddressList(updatedAddressList);
							
							// Clear the form and states
							form.setFieldsValue({ 
								addressList: undefined,
								region: undefined,
								commune: undefined,
								direction: ''
							});
							setSelectedRegion(undefined);
							setSelectedCommune(undefined);
							setDirectionValue('');
							setAddressListItemSelected(false);
							setAddressSaved(false);
							setPreviousLocation(null); // Clear history
		
							CustomNotification({
								type: NOTIFICATION.TYPE.INFO,
								message: NOTIFICATION.MESSAGE.INFO,
								description: 'Dirección cancelada'
							});
							
							return true;
						})
						.catch(e => {
							console.error("Error canceling address:", e);
							CustomNotification({
								type: NOTIFICATION.TYPE.ERROR,
								message: NOTIFICATION.MESSAGE.ERROR,
								description: 'Hubo un problema al cancelar la dirección'
							});
							return false;
						});
				}
			} catch (error) {
				console.error("Error deleting address:", error);
				return false;
			}
		};
	
		const handleOkModal = async () => {
			if (!isAddressSaved) {
				setLoadingButton(true);
				const result = await saveNewAddress(); 
				setLoadingButton(false);
				
				if (!result) {
					console.error("Error while saving new address");
					return; 
				}
			}
			setModalAddress(false);
			setCurrentStep(0);
			if (location.pathname.match(/cart/)) {
				history.go(0);
				window.location.reload();
				window.scrollTo(0, 0);
			}
	
			if (location.pathname.match(/^\/products\//)) {
				window.location.reload();
				window.scrollTo(0, 0);
			}
	
		};
	
		const handleOpenModal =  () => {
			setModalAddress(true);
		};
	
		useEffect(()=>{
			const getUserAddress = async () => {
				if (isUserToken) {
					try {
						const addresses = await new Service().getUserdAddress(isUserToken);
						setUserAddressList(addresses);
										
						const options = addresses.map((address, i) => ({
							label: address.alias,
							value: address.location.formatted_address,
							 key: `address-${i}-${address.location.formatted_address}`
						}));
		
						if (options.length !== 0) {
							options.unshift({ label: "Nueva dirección", value: "" ,  key: "new-address"})
						}
						
						setFormattedAddressOptions(options);
					} catch (error) {
						console.error("Error al obtener las direcciones del usuario", error);
					}
				}
			}
			getUserAddress()
	
		},[isUserToken, isUserInfo, isAddressDisplay])
	
	
		
	
		const handleNextStep = () => {
			setCurrentStep(currentStep + 1);
		};
	
		const handleBackStep = () => {
			setCurrentStep(currentStep - 1);
		};
	
		const handleLoggedBackStep = () => {
			if (selectedUserAlias && selectedUserAlias !== "") {
				handleBackStep();
				return;
			}
					
			setAddressSaved(false);
			setSaveAddressChecked(false);
			setAliasValue('');
			form.setFieldsValue({ alias: "" });
			setAddressListItemSelected(false);
					
			handleBackStep();
		}
	
		const handleLogin = () => {
			history.push('/auth/login');
			handleCloseModal()
		};
	
		const handleDirectionChange = (e) => {
			setDirectionValue(e.target.value);
		};
	
		const handleSaveAddress = (e) =>{
			setSaveAddressChecked(e.target.checked);
		}
	
		const handleAliasChange = (e) => {
			setAliasValue(e.target.value);
		};
	
		const handleUserAddressChange = (value) => {
			setSelectedUserAlias(value);
			form.setFieldsValue({addressList: value})
			if (value === "") { 
				
				setAddressListItemSelected(false)
				setSelectedRegion(undefined);
				setSelectedCommune(undefined);
				setDirectionValue('');
				form.setFieldsValue({ region: undefined, commune: undefined, direction: '' });
			} else if (isUserToken && isLocation && formattedAddressOptions.length > 0) {
				const index = userAddressList.findIndex(item => item.location.formatted_address === value);
				
				if (index !== -1) {
					const [region, commune, direction] = userAddressList[index].formFields.split(',').map(item => item.trim());
					
					setAddressListItemSelected(true)
					setSelectedRegion(region);
					form.setFieldsValue({ region });
	
					setSelectedCommune(commune);
					form.setFieldsValue({ commune });
	
					setAliasValue(direction);
					form.setFieldsValue({ direction });
				} else {
					console.log("An element with the specified alias was not found.");
				}
			}
		
			setDisableContinueButton(!(form.getFieldValue('region') && form.getFieldValue('commune') && form.getFieldValue('direction')));
		};
	
		useEffect(() => {
			if (
				location.pathname.match(/cart/) ||
				location.pathname.match(/product-check/) ||
				(location.pathname.match(/electronic-prescription/) && !location.pathname.match('/on_demand'))
			) {
				if (isLocation.default && attempts === true) {
					setAttempts(false);
				}
			}
		}, [isModalAddress, isLocation, setModalAddress, location.pathname, attempts]);
	
		useEffect(() => {
			setAddressDisplay(isLocation);
			
			const onDemand = JSON.parse(localStorage.getItem('onDemandData')) ? true : false;
			setGlobal(() => ({ onDemandCart: onDemand }));
			// eslint-disable-next-line
		}, [isLocation, newAddress]);
	
		useEffect(() => {
				FindIpAdapter().then((response) => {
				StorageSetItemAdapter(LocalStorage.location, ResponseIpAdapter(response));
			});
		}, []);
	
	
		useEffect(() => {
			const defaultAddress = latlng ?? isLocation.formFields;
			
			geocodeAddress(defaultAddress)
				.then((response) => {
					setCoordinates(response);
				})
				.catch((e) => {
					setDisableContinueButton(true)
					console.log('error in geocodeAddress service:', e);
					CustomNotification({ type: 'error', message: null, description: 'La dirección que estas intentando buscar no existe' });
				}); // eslint-disable-next-line
		}, [form]); 
	
	
		useEffect(() => {
			if(regionOptions.length === 0 && currentStep === 0){
				new Service()
					.getRegionsByCountry()
					.then((response) => {
						const options = response.data.map((region) => ({ label: region, value: region }));
						setRegionOptions(options);
					})
					.catch((e) => {
						CustomNotification({
							type: NOTIFICATION.TYPE.ERROR,
							message: NOTIFICATION.MESSAGE.ERROR,
							description: 'Hubo un problema actualizando los recursos 1'
						});
						console.log("error", e);
					});
			}
		}, [currentStep, regionOptions.length]);
	
		const handleRegionChange = async (regionValue) => {
			const trimmedRegionValue = regionValue.trim();
			setSelectedRegion(trimmedRegionValue); 
			setSelectedCommune(undefined)
			form.setFieldsValue({ commune: undefined });
			setCommuneOptions([])
				new Service()
					.getCommunesByRegion(regionValue)
					.then((response) => {
						const options = response.data.map((region) => ({ 
							label: region, 
							value: region 
						}));
						setCommuneOptions(options);
					})
					.catch((e) => {
						CustomNotification({
							type: NOTIFICATION.TYPE.ERROR,
							message: NOTIFICATION.MESSAGE.ERROR,
							description: 'Hubo un problema actualizando los recursos 2'
						})
						console.log("error in getCommunesByRegion service", e)
					});
		
		};
	
		const handleCommuneChange = (value) => {
			setSelectedCommune(value); 
		};
	
		useEffect(() => {
			setDisableContinueButton(!(form.getFieldValue('region') && form.getFieldValue('commune') && form.getFieldValue('direction')));
		}, [selectedRegion, selectedCommune, directionValue, selectedUserAlias, form]);
	
		useEffect(() => {
			if (isLocation && isLocation.formFields) {
				const [region, commune, direction] = isLocation.formFields.split(',').map(item => item.trim());
	
				const currentRegion = form.getFieldValue('region');
				const currentCommune = form.getFieldValue('commune');
				const currentDirection = form.getFieldValue('direction');
	
				if (currentRegion !== region || currentCommune !== commune || currentDirection !== direction) {
					setSelectedRegion(region);
					form.setFieldsValue({ region });
	
					new Service()
						.getCommunesByRegion(region)
						.then((response) => {
							const options = response.data.map((commune) => ({ 
								label: commune, 
								value: commune 
							}));
							setCommuneOptions(options);
	
							setSelectedCommune(commune);
							form.setFieldsValue({ commune });
						})
						.catch((e) => {
							CustomNotification({
								type: NOTIFICATION.TYPE.ERROR,
								message: NOTIFICATION.MESSAGE.ERROR,
								description: 'Hubo un problema actualizando los recursos 3'
							});
							console.log("error in getCommunesByRegion service", e);
						});
	
					setDirectionValue(direction);
					form.setFieldsValue({ direction });
				}
	
				const lastUsedAddress = userAddressList.find(item => item.formFields === isLocation.formFields);
				console.log("no entra", {lastUsedAddress}, {userAddressList})
				console.log("Valparaíso, Concón, Av. Concón Reñaca 296" === " Valparaíso, Concón, Av. Concón Reñaca 296")
				if (lastUsedAddress && currentStep === 0) {
					setSelectedUserAlias(lastUsedAddress.alias);
					form.setFieldsValue({ addressList: lastUsedAddress.alias });
					setAddressListItemSelected(true);
				} else if (form.getFieldValue('addressList') === "Nueva dirección") {
					setAddressListItemSelected(false);
				} 
			} // eslint-disable-next-line
		}, [isLocation, form, userAddressList]);
	
		useEffect(() => {
			if (isLocation && !previousLocation) {
				setPreviousLocation(isLocation);
			}
		}, [isLocation, previousLocation]);
	
		const stepProps = {
			form,
			regionOptions,
			communeOptions,
			selectedRegion,
			selectedCommune, 
			directionValue,
			coordinates,
			newAddress,
			handleRegionChange,
			handleCommuneChange,
			handleDirectionChange,
			handleGetAddress,
			handleConfirmAddress,
			handleBackStep,
			handleOkModal,
			isLoadingButton,
			disableContinueButton,
			saveAddressChecked,
			setSaveAddressChecked,
			aliasValue,
			handleAliasChange,
			isAddressSaved,
			handleLogin,
			handleDeleteAddress,
			handleLoggedBackStep,
			handleSaveAddress,
			userAddressList,
			formattedAddressOptions,
			handleUserAddressChange,
			selectedUserAlias,
			isAddressListItemSelected
		  };
	
		return (
			<>
				<div className={handleAddressContainer(location)} onClick={() => handleOpenModal()}>
					<Images classContent={'ph-address-image-logo-container'} classImage={'ph-address-image-logo'} default={handleLocationImage()} title={'location'} />
					 <div className="ph-address-description-container">
						{isAddressDisplay && (
							<>
								<p className="ph-address-description-1">{isLocation.formFields?.split(',')[2].trim() ?? isAddressDisplay.street}</p>
								<p className="ph-address-description-2">
									{isAddressDisplay.commune}, {isAddressDisplay.region}
								</p>
							</>
						)}
					</div> 
				</div>
				<CustomModal
					wrapClassName="ph-address-modal-container"
					maskClosable={false}
					width="900px"
					title="Tu dirección"
					visible={isModalAddress}
					onCancel={() => handleCloseModal()}
					cancelButtonProps={{ style: { display: 'none' } }}
					okButtonProps={{ style: { display: 'none' } }}
				>
					<Row>
						<Col className={`ph-address-modal-map-container ${ currentStep === 0 ? 'display-none' : ''}`} xs={24} sm={24} md={12} lg={12} xl={12}>
							{isAddressDisplay && <GoogleMap coordinates={coordinates}/>}
						</Col>
						<Col className='ph-address-modal-form-container'  xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form className='ph-address-form'  form={form}>	
								<AddressSteps 
									isUserToken={isUserToken}
									currentStep={currentStep}
									stepProps={stepProps}
								/> 
												
							</Form>
						</Col>
					</Row>
				</CustomModal>
			</>
		);
	}



	/* 
	export default function AddressComponent() {
		const { t } = useTranslation('global');
		const { isModalAddress, setModalAddress, isLocation, setLocation } = GlobalConsumer();
		const history = useHistory();
		const location = useLocation();
		const [isLoadingButton, setLoadingButton] = useState(false);
		const [isAddressList, setAddressList] = useState([]);
		const [isAddressDisplay, setAddressDisplay] = useGlobal('address');
		const [, setNearPharmacy] = useGlobal('mapPharmacy');
		const [isOnDemand] = useGlobal('onDemandCart');
		const [attempts, setAttempts] = useState(true);
		const [form] = Form.useForm();
		const [currentStep, setCurrentStep] = useState(0);
		const [regionOptions, setRegionOptions] = useState([]);
		const [communeOptions, setCommuneOptions] = useState([]);
		const [selectedRegion, setSelectedRegion] = useState(undefined);
		const [coordinates, setCoordinates] = useState(null);
		const [saveAddressChecked, setSaveAddressChecked] = useState(false);

	const handleSearchAddress = async () => {
		const value = form.getFieldValue('address');

		setLoadingButton(true);
		await AddressSearch(value).then((response) => {
			setAddressList(response);
		});
		setLoadingButton(false);
	};

	const handleSetLocation = async (item) => {
		console.log('handleSetLocation', item);
		let address = null;
		setLoadingButton(true);
		setAddressList([]);
		await AddressSetLocation(item).then((response) => {
			address = response;
			PharmacySetLocation(address).then((response) => {
				setNearPharmacy(response);
			});
		});
		setLocation(address);
		form.resetFields();
		setLoadingButton(false);
		setGlobal({ collapseMapPharmacies: [] });
		setGlobal(() => ({ userAddressList: address }));
	};
	const handleCloseModal = () => {
		setModalAddress(false);
	};

	const handleOkModal = () => {
		setModalAddress(false);
		if (location.pathname.match(/cart/)) {
			history.go(0);
		}

		window.location.reload();
		window.scrollTo(0, 0);
	};

	const handleOpenModal = () => {
		setModalAddress(true);
	};

	useEffect(() => {
		if (
			location.pathname.match(/cart/) ||
			location.pathname.match(/product-check/) ||
			(location.pathname.match(/electronic-prescription/) && !location.pathname.match('/on_demand'))
		) {
			console.log('isLocation', isLocation);
			if (isLocation.default && attempts === true) {
				setAttempts(false);
			}
		}
	}, [isModalAddress, isLocation, setModalAddress, location.pathname, attempts]);

	useEffect(() => {
		PharmacySetLocation(isLocation).then((response) => {
			setNearPharmacy(response);
		});
		setAddressDisplay(isLocation);
		const onDemand = JSON.parse(localStorage.getItem('onDemandData')) ? true : false;
		setGlobal(() => ({ onDemandCart: onDemand }));
	}, [isLocation, setAddressDisplay, setNearPharmacy]);

	useEffect(() => {
		FindIpAdapter().then((response) => {
			StorageSetItemAdapter(LocalStorage.location, ResponseIpAdapter(response));
		});
	}, []);

	return (
		<>
			<div className={handleAddressContainer(location)} onClick={() => handleOpenModal()}>
				<IconLocation className={'icons-theme-address'} />
				<div className="ph-address-description-container">
					{isAddressDisplay && (
						<>
							<p className="ph-address-description-1">{isAddressDisplay.street}</p>
							<p className="ph-address-description-2">
								{isAddressDisplay.commune}, {isAddressDisplay.region}
							</p>
						</>
					)}
				</div>
			</div>
			<Modal
				wrapClassName="ph-address-modal-container"
				maskClosable={false}
				title="Gestión de Direcciones"
				centered
				visible={isModalAddress}
				onCancel={() => handleCloseModal()}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Row justify="center">
					<Col className="ph-address-modal-title-container" xs={24} sm={24} md={24} lg={24} xl={24}>
						<h5 className="ph-address-modal-description">{t('address.modal.subtitle')}</h5>
						<div className="ph-address-form-main-container">
							<Form className="ph-address-form-container" form={form}>
								<InputField
									disabled={isOnDemand}
									className={'ph-address-search-field'}
									inputName={'address'}
									inputNameLabel={t('address.modal.formInput')}
									inputNameType={'text'}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu correo......'}
									onChange={() => setAddressList(null)}
									autocomplete="off"
								/>
								<Button
									disabled={isOnDemand}
									loading={isLoadingButton}
									className="ph-address-search-button"
									htmlType="submit"
									icon={<SearchOutlined />}
									onClick={handleSearchAddress}
								/>
							</Form>

							<div className="ph-address-search-list">
								{isAddressList && (
									<Menu>
										{isAddressList.map((address, index) => (
											<Menu.Item onClick={() => handleSetLocation(address.name)} className="ph-address-search-list-description" key={index}>
												<Images
													classContent={'ph-address-search-list-image-container'}
													classImage={'ph-address-search-list-image'}
													default={handleLocationImage()}
													title={'location'}
												/>
												{handleShortName(address.name)}
											</Menu.Item>
										))}
									</Menu>
								)}
							</div>
						</div>
					</Col>
					<Col span={24}>
						{isAddressDisplay && (
							<>
								<div className="ph-address-box-inner-main-container">
									<div className="ph-address-box-address-display-container">
										<h3 className="ph-address-box-title">Tu dirección actual es:</h3>
										<div className="ph-address-box-inner-container">
											<Images
												classContent={'ph-address-box-image-container'}
												classImage={'ph-address-image-logo'}
												default={handleLocationImage()}
												title={'location'}
											/>
											<div className="ph-address-description-container">
												<p className="ph-address-box-description-1">{isAddressDisplay.street}</p>
												<p className="ph-address-box-description-2">
													{isAddressDisplay.commune}, {isAddressDisplay.region}
												</p>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</Col>
					<Col span={24}>{isAddressDisplay && <MapAddress address={isAddressDisplay} />}</Col>
					<Col span={24}>
						<br></br>
						<CustomButton className={'button-primary'} onClick={() => handleOkModal()}>
							Confirmar
						</CustomButton>
					</Col>
				</Row>
			</Modal>
		</>
	);
} */
