import React from 'react';
import { NoLoggedSteps } from './components/NoLoggedSteps';
import { LoggedSteps } from './components/LoggedSteps';

export const AddressSteps = ({
  isUserToken,
  currentStep,
  stepProps
}) => {
  return (
    <>
      {isUserToken ? (
        <LoggedSteps 
          currentStep={currentStep}
          isUserToken={isUserToken}
          {...stepProps}
        />
      ) : (
        <NoLoggedSteps 
          currentStep={currentStep}
          isUserToken={isUserToken}
          {...stepProps}
        />
      )}
    </>
  );
};