/** @format */

import React, { useEffect } from 'react';

import { useGlobal } from 'reactn';

import { GlobalConsumer } from '../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../components/Commons/Context/Bag';

import { Row, Col, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

// import Images from '../../../../components/Commons/Images';

import BagProducts from './components/BagProducts';
import BagTotalInfo from './components/BagTotalInfo';
import BagTypeDelivery from './components/BagTypeDelivery';
import BagVoucher from './components/BagVoucher';

import { handleLocals } from '../../functions/BagCalculation/components/BagLocals';
import { Calculation } from '../../functions/BagCalculation/services';
import { BagMerge } from '../../functions/BagMergeLocals';
import { handleCountPharmacies } from '../../functions/BagCountPharmacies';

import { GetUserData } from '../../../../components/Commons/Hooks/Functions/GetUserData';

import BagTotal from '../../functions/BagTotal';
import './style.scss';
import CustomImage from '../../../../v2-components/Antd/Image';

export default function BagProductsGroup(props) {
	const [isBagVoucherPharmacies] = useGlobal('bagVoucherPharmacies');
	const { isBag, isLocation, isLoginUser } = GlobalConsumer();
	const { isBagData, setBagDeliveryPharmacies, isBagRetirement, setBagData, setBagTotalCart, isLoadingGroup, setLoadingGroup } = BagConsumer();

	const { handleBagTotal } = BagTotal();

	useEffect(() => {
		async function ProcesCart() {
			let array = [];
			let pharmacies = {};
			let locals_group = isBagData.locals_group;

			let userData;
			if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);
			/* eslint-disable */
			for (const item of isBagData.locals_group) {
				array.push([item.name, false]);
				pharmacies = { ...pharmacies, [item.name]: null };
				item.products.forEach((element) => {
					delete element.type_retirement;
				});

				await Calculation(
					item.products,
					isLocation,
					isLoginUser.email,
					isLoginUser.token,
					isBagVoucherPharmacies,
					handleLocals(isBag),
					'domicilio',
					userData,
					false,
					handleCountPharmacies(),
				).then(async (response) => {
					let responseBag;
					if (response) {
						const localsMerged = await BagMerge(response.locals_group);
						const returnResponse = { ...response, locals_group: localsMerged };

						if (returnResponse.locals_group[0].locals.length > 0) {
							responseBag = returnResponse.locals_group[0];
						} else responseBag = false;
					} else responseBag = false;

					pharmacies[item.name] = responseBag;
					if (responseBag && isBagRetirement && isBagRetirement[item.name] && isBagRetirement[item.name].type_retirement === 'domicilio') {
						const index = isBagData.locals_group.findIndex((search) => {
							return search.name === responseBag.name;
						});
						locals_group[index] = responseBag;
						const bagData = { ...isBagData, locals_group: locals_group };
						setBagData(bagData);
						setBagTotalCart(handleBagTotal(bagData));
					}
				});
				setBagDeliveryPharmacies({ ...pharmacies });
				setLoadingGroup(Object.fromEntries(array));
			}
			/* eslint-enable */
		}
		ProcesCart();
	}, []); // eslint-disable-line

	return (
		<>
			{isBagData.locals_group.map((item, index) => (
				<div key={index} className="ph-bag-products-group-main-container">
					{isLoadingGroup[item.name] && (
						<div className="ph-bag-product-list-loading-container">
							<div className="ph-bag-product-list-loading-inner-container">
								<Spin className="ph-bag-product-list-loading"
											indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
								<h4 className="ph-bag-product-list-loading-title">Espere por favor...</h4>
							</div>
						</div>
					)}
					<div className="ph-bag-products-group-inner-container">
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<div className="ph-bag-products-group-phamracy-title-container">
									{/*<span className="ph-bag-products-group-phamracy-title-number">{index + 1}</span>*/}
									{/*<Images*/}
									{/*	classContent={'ph-bag-products-group-phamracy-image-container'}*/}
									{/*	classImage={'ph-bag-products-group-phamracy-image'}*/}
									{/*	default={item.image}*/}
									{/*	title={item.name_editable}*/}
									{/*/>*/}
									<CustomImage className="ph-bag-products-group-phamracy-icon" src={item.image}/>
									<div>
										<h4 className="ph-bag-products-group-phamracy-title-pharmacy">
											{item.name_editable}
										</h4>
										{/*<h4 className="ph-bag-products-group-phamracy-title-pharmacy">{item?.locals[0]?.name_local}</h4>*/}
										{/*<h4 className="ph-bag-products-group-phamracy-title-description">Seleccione un tipo de Entrega...</h4>*/}
									</div>
								</div>
								<div>
									<h4 className="ph-bag-products-group-phamracy-title-description">

									</h4>
								</div>
							</Col>
							{/*<Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
							{/*	{console.log('item', item)}*/}
							{/*	<BagTypeDelivery item={item} />*/}
							{/*</Col>*/}
						</Row>
					</div>
					<h4 className="ph-bag-product-list-title">Productos</h4>
					<BagProducts
						index={index}
						products={item.products}
						name={item.name}
						cartForm={props.cartForm}
						productsSelected={props.productsSelected}
						setProductsSelected={props.setProductsSelected}
					/>
					<BagVoucher cartForm={props.cartForm} item={item} />
					<Row>

						<Col span={24}>
							<h4 className="ph-bag-product-list-title">Tipo de entrega</h4>
							<BagTypeDelivery item={item} />
						</Col>
					</Row>
					<BagTotalInfo info={item} />
				</div>
			))}
		</>
	);
}
