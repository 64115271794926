import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import CustomImage from '../../../v2-components/Antd/Image';
// import CustomButton from '../../../../../v2-components/Antd/Button';
import CustomCard from '../../../v2-components/Antd/Card';
// import { ReactComponent as IconHeartLineal } from '../../../../../components/Commons/Assets/Icons/Lineals/Disabled/favorite.svg';
//import { ReactComponent as iconFilledArg } from '../../../../../components/Commons/Assets/Icons/Filled/Enabled/Argentina/favoritos1-1-verde.png'
import { handleStock } from '../handle/stock.handle';
import { handleMecanic } from '../handle/mecanic.handle';
import { handlePrice } from '../handle/price.handle';
import { handlePromotionPrice } from '../handle/promotion-price.handle';
import { handleLaboratory } from '../handle/laboratory.handle';
import { handleName } from '../handle/name.handle';
import { handleSince } from '../handle/since.handle';
import { handleTagIcon } from '../handle/tag-icon.handle';
import { handleOriginalImage } from '../handle/original-image.handle';
import { handleRecipeIcon } from '../handle/recipe-icon.handle';
import { image } from '../constant/image.constant';
import './style.scss';

// import { FavoriteActions } from '../../../components/Commons/Favorites/Components';
import Button from '../../../components/Commons/Product/components/Button';
import { ModalTypeRecipes } from '../../../components/Commons/Modals/ModalTypeRecipes';
import { handlePharmacy } from '../handle/pharmacy.handle';
import { linkToDetailProduct } from '../../../components/Commons/Hooks/Functions/LinkToDetailProduct';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { cenabastIconHandle } from '../handle/cenabast-icon.handle';




export default function CardProductDesktop(props) {
	console.log('props', props);
	const [imageSrc, setImageSrc] = useState(props.product.original_image);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const history = useHistory()

	const handleTypeRecipes = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		setImageSrc(props.product.original_image);
	}, [props.product.original_image]);

	const handleImageError = () => {
		setImageSrc(image.default);
	};
	const handleStopPropagation= (event)=>{
		event.stopPropagation()
	}

	return (
		<>
			<CustomCard className="own-card-product-desktop" onClick={()=>history.push(linkToDetailProduct(props.product))}>
				<Row gutter={4} className="own-image-icons-container">
					<Col span={4} className="own-card-icons-container" onClick={handleStopPropagation}>
						{handleTagIcon(props.product)}
						<div className="own-card-icons-sub-container" onClick={handleTypeRecipes}>

							{handleRecipeIcon(props.product)}
							{cenabastIconHandle(props.product)}


						</div>
						<ModalTypeRecipes isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
					</Col>
					<Col span={16}>
						<Row gutter={16}>
							<CustomImage src={handleOriginalImage(imageSrc)} width={150} onError={handleImageError} />
						</Row>
					</Col>
					{/*Remove comment once favorites is working */}
					{/*<Col span={4} className="own-favorite-container" onClick={handleStopPropagation}>*/}
					{/*	<FavoriteActions item={props.product} listProductFavorite={props.listProductFavorite} />*/}
					{/*</Col>*/}
				</Row>
				<Row justify="start">{handleLaboratory(props.product, 'own-laboratory-label')}</Row>
				<Row justify="center">{handleName(props.product, 'own-product-title')}</Row>
				<Row justify="start">{handlePharmacy(props.product, 'temporary-pharmacy-icon')}</Row>
				<Row justify="start">{handleStock(props.product, 'own-stock')}</Row>
				<Row className="own-promotion-prices-container">
					<Row justify="start">{handleSince(props.product, 'own-since')}</Row>
					<Row justify="center">{handleMecanic(props.product, 'own-mecanic')}</Row>
					<Row className="own-price-container">
						{handlePromotionPrice(props.product, 'own-promotion-price')}
						{handlePrice(props.product, 'own-final-price')}
					</Row>
				</Row>
				<Row justify="center" onClick={handleStopPropagation}><Button available={props.product.available} detail={props.product} /></Row>

			</CustomCard>
		</>
	);
}




























