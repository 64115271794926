import React from 'react';
import { Form, Checkbox, Row, Col} from 'antd';
import { Link } from 'react-router-dom';
import BillModal from '../PersonalData/BillModal';
import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';

const AgreementCheckbox = ({ form, date, billing, setBilling, setPersonalData }) => {
    return (
        <Row>
            <Col span={24}>
                <div className="ph-withdrawal-personal-data-agree-main-container">
                    <Form.Item
                        className="ph-withdrawal-personal-data-agree-container"
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Acepta las condiciones para continuar.')),
                            },
                        ]}
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                    <h4 className="ph-withdrawal-personal-data-agree-title">
                        Acepto los
                        <Link className="ph-withdrawal-personal-data-agree-link" to="/terms-conditions">
                            {' '}
                            Términos, Condiciones
                        </Link>{' '}
                        y
                        <Link className="ph-withdrawal-personal-data-agree-link" to="/politics">
                            {' '}
                            Políticas de Privacidad
                        </Link>
                    </h4>
                    {ENV_COUNTRY === 'MX' && <BillModal form={form} date={date} billing={billing} setBilling={setBilling} setPersonalData={setPersonalData} />}
                </div>
            </Col>
        </Row>
    );
};

export default AgreementCheckbox;