/** @format */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal, setGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import { GetUserData } from '../../../../components/Commons/Hooks/Functions/GetUserData';
import { PixelFacebook } from '../../../../components/Commons/Hooks/Functions/PixelFacebook';
import { amountProductsCart } from '../../../../components/Commons/Hooks/Functions/AmountProductsCart';
import { GetOrder } from '../../../../components/Commons/Hooks/Functions/GetOrder';
import Notify from '../../../../components/Commons/Notify';
import MetaDescription from '../../../../components/Commons/MetaDescription';

import PersonalData from './components/PersonalData';
import PharmacySelect from './components/PharmacySelect';
import DeliverySelect from './components/DeliverySelect';
import ButtonRetirement from './components/ButtonRetirement';

import CheckoutTimeLine from '../../../../components/CheckoutTimeLine';
import CheckoutGlobalTitle from '../../../../components/CheckoutGlobalTitle';
import Countdown from '../../../../components/Countdown';

import PaymentDetail from '../../components/PaymentDetail';
import CardOnDemand from '../../components/CardOnDemand';
import LoadingService from '../../components/LoadingService';

import { ReserveCheckSave } from './service';

import { ENV_ISO_CURRENCY } from '../../../../components/Commons/Hooks/Variables/Enviroment';
import { Enviroment } from '../../../../_config/constants/enviroment-v2.constant';
import ScrollToTop from '../../../../v2-components/ScrollToTop';
import AgreementCheckbox from './components/AgreementCheckbox';

import './style.scss';

export default function Retirement() { 
	const { t } = useTranslation('global');
	const history = useHistory();
	const [form] = Form.useForm();
	const [isUserEmail] = useState(localStorage.getItem('userEmail'));
	const [isProductsCooled, setProductsCooled] = useState(false);
	const [isUserAddress] = useState(JSON.parse(localStorage.getItem('locationStore')));
	const [isRetirementData, setRetirementData] = useGlobal('retirementData');
	const [isOnDemand] = useState(JSON.parse(localStorage.getItem('onDemandData')));
	const [personalData, setPersonalData] = useState({});
	const [isMobile, setMobile] = useState(false);
	const [isVisible, setVisible] = useState(false);
	const [billing, setBilling] = useState({
		modal: false,
		check: false,
	});
	const [date] = useState(new Date());

	useEffect(() => {
		let info = JSON.parse(localStorage.getItem('retirementData'));
		const userCheckout = JSON.parse(localStorage.getItem('form_retirement'));
		const phone = userCheckout?.phone?.replace(/^56/,'')
		let productsIDs = [];
		info.products.forEach((item) => {
			if (item.id_pharol) productsIDs.push(`${item.id_pharol}`);
			if (item.cooled === '1') setProductsCooled(true);
		});
		console.log('userCheckout', userCheckout);
		console.log('isOnDemand', isOnDemand);
		form.setFieldsValue({
			...userCheckout,
			email: isUserEmail ? isUserEmail : userCheckout?.email,
			street: isUserAddress.street,
			commune: isUserAddress.commune,
			phone:phone,
			type_house: null,
			reference: null,
			street_number: null,
			number_dpto_house: null,
		});
		setRetirementData(info); 
		if (isUserEmail && !isOnDemand) {
			GetUserData(isUserEmail).then((response) => {
				console.log('response', response);
				form.setFieldsValue(response);
			});
		}
		if (isOnDemand) {
			form.setFieldsValue({
				first_name: isOnDemand.first_name,
				last_name: isOnDemand.last_name,
				email: isOnDemand.email,
				phone: isOnDemand.phone,
				rut: isOnDemand.rut,
			});
		}
		console.log('form', form.getFieldValue('phone'));
		PixelFacebook('InitiateCheckout', {
			content_ids: productsIDs,
			content_type: 'product_group',
			currency: ENV_ISO_CURRENCY,
			num_items: amountProductsCart(),
			value: parseInt(info.total).toFixed(2),
		});
		if (window.innerWidth < 992) {
			setMobile(true);
		}
	}, [isUserEmail, setRetirementData, isUserAddress, form, isOnDemand]);

	// const getTypeDocument = () => {
	// 	let cart = JSON.parse(localStorage.getItem('cart'));
	// 	if (!Array.isArray(cart)) {
	// 		cart = [];
	// 	}

	// 	if (cart) {
	// 		let requireRecipe = cart.some((product) => {
	// 			return product.prescription_type !== 'NP';
	// 		});

	// 		let checkNamePharmacy = cart.some((product) => {
	// 			return product.pharmacy !== cart[0].pharmacy;
	// 		});

	// 		if (requireRecipe || checkNamePharmacy) {
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}
	// 	}
	// };

	const handleSubmitRetirement = async (item) => {
		let info = JSON.parse(localStorage.getItem('retirementData'));
		let retirement = { ...isRetirementData };

		if (isRetirementData.locals_group.some((group) => group.type_retirement === 'farmacia')) {
			let allSelected = [];
			isRetirementData.locals_group.forEach((group) => {
				if (group.type_retirement === 'farmacia') {
					allSelected.push(!!group.local_selected);
				}
			});
			if (!allSelected.every((item) => item === true)) {
				Notify({
					title: `¡Sin local!`,
					colorTitle: 'ph-main-notification-error-title',
					description: `Por favor termine de seleccionar los locales de retiro.`,
					time: 3,
					image: 'error',
					placement: 'topRight',
				});
				return;
			}
		}

		retirement['pharmacy'] = [];
		retirement.locals_group.forEach((group) => {
			let newFields = {
				description: `${group.name_editable}, ${group.locals[0].direction}, ${group.locals[0].distance_text}`,
				schedule_selected: group.type_calculation !== 'quotation' ? `${item[`date_schedule_${group.name}`]}; ${item[`day_schedule_${group.name}`]}` : null,
				detail_pharmacy: `${group.name}, ${group.locals[0].distance_text}, ${group.locals[0].duration}`,
			};

			if (group.schedule_delivery && group.type_calculation !== 'quotation') {
				group.schedule_delivery.forEach((element) => {
					if (item[`date_schedule_${group.name}`] === element.date) {
						element.timers.forEach((data) => {
							if (`${item[`day_schedule_${group.name}`]}` === `${data.start_time} - ${data.end_time}`) {
								newFields = { ...newFields, schedule_object: data };
							}
						});
					}
				});
			}

			'local_selected' in group
				? retirement['pharmacy'].push({ ...group.local_selected, ...newFields })
				: retirement['pharmacy'].push({ ...group.locals[0], ...newFields });
		});

		setGlobal({ retirementButtonLoading: true });
		setVisible(true);
		console.log('item', item);
		item.phone = `56${item.phone}`; // FIX ME: HARDCODED COUNTRY CODE OF CHILE
		await GetOrder({
			...retirement,
			...item,
			isUserAddress,
			type_retirement: retirement.pharmacy.some((element) => element.type_retirement === 'domicilio') ? 'domicilio' : 'farmacia',
			info_billing: personalData?.info_billing,
		}).then((response) => {
			if (response) {
				setGlobal({ retirementButtonLoading: false });
				localStorage.setItem('paymentData', JSON.stringify(response));
				console.log('response, valida orden: ', response);

				if (Enviroment.ROOT_RESERVE) {
					ReserveCheckSave('mercagopago-ar', response, info.total)
						.then((response) => {
							history.push('/transactions/success-reserve');
						})
						.catch((e) => {
							history.push('/transactions/error-reserve');
						});
				} else {
					return history.push('/checkout/payment');
				}
			}
		});
	};

//	console.log('form phone', form.getFieldValue('phone'));

	return (
		<>
		<ScrollToTop/>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			{isRetirementData && (
				<>
					<div className="ph-withdrawal-global-container">
						<div className="ph-withdrawal-main-container">
							<CheckoutTimeLine step={1} />
							<div className="ph-withdrawal-titlebox-countdown-container">
								<CheckoutGlobalTitle
									image={'tracking'}
									title={'Datos de entrega'}
									// subtitle1="Completa tus datos para finalizar"
									checkout={true}
								/>
								<Countdown />
							</div>
							<Form onFinish={handleSubmitRetirement} name="retirement_form" form={form}>
								<Row>
									<Col xs={24} sm={24} md={24} lg={17} xl={17}>
										<PersonalData
											form={form}
											personalData={personalData}
											isRetirementData={isRetirementData}
											billing={billing}
											setBilling={setBilling}
											setPersonalData={setPersonalData}
										/>
										{isRetirementData.locals_group.map((group, index) => (
											<React.Fragment key={index}>
												{group.type_retirement === 'domicilio' ? (
													<>
														<DeliverySelect
															index={index}
															group={group}
															isMobile={isMobile}
															checkout={isRetirementData}
															isProductsCooled={isProductsCooled}
															isUserAddress={isUserAddress}
															isUserEmail={isUserEmail}
															form={form}
														/>
													</>
												) : (
													<>
														<PharmacySelect
															index={index}
															group={group}
															userAddress={isUserAddress}
															isRetirementData={isRetirementData}
															setRetirementData={setRetirementData}
														/>
													</>
												)}
											</React.Fragment>
										))}
									</Col>
									<Col xs={24} sm={24} md={24} lg={7} xl={7} className="ph-withdrawal-checkout-box-global-container">
										<CardOnDemand />
										<div className="ph-withdrawal-checkout-box-main-container">
											<PaymentDetail 
											address={isUserAddress} 
											item={isRetirementData} 
											fromWithdrawal={true} 
											/>
											<ButtonRetirement item={isRetirementData} />

											<AgreementCheckbox 
												form={form}
												date={date}
												billing={billing}
												setBilling={setBilling}
												setPersonalData={setPersonalData}
											/>
										</div>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
					<LoadingService visible={isVisible} />
				</>
			)}
		</>
	);
}
