import React from 'react';
import { Row, Col } from 'antd';
import CustomFormItem from '../../../../../../../../../v2-components/Antd/FormItem';
import CustomInput from '../../../../../../../../../v2-components/Antd/Input';
import CustomButton from '../../../../../../../../../v2-components/Antd/Button';
import CustomCheckbox from '../../../../../../../../../v2-components/Antd/Checkbox';

export const StepThree = ({
  newAddress,
  isLogged,
  isAddressListItemSelected,
  saveAddressChecked,
  handleSaveAddress,
  aliasValue,
  handleAliasChange,
  isAddressSaved,
  handleLoggedBackStep,
  handleDeleteAddress,
  handleOkModal,
  isLoadingButton
}) => {
  return (
    <>
      <h4 className='ph-address-modal-form-title'>
        Dirección actualizada correctamente
      </h4>

      <h5 className='ph-address-modal-form-sub-title'>
        Dirección ingresada
      </h5>

      <p className='form-address-modal-text-address-confirmed'>
        {newAddress?.formFields}
      </p>

      {!isAddressListItemSelected && isLogged && (
        <>
          <CustomFormItem
            name='saveAddress'
            className='form-item-street'
          >
            <CustomCheckbox
              checked={saveAddressChecked}
              onChange={handleSaveAddress}
              disabled={isAddressSaved}
            >
              Guardar en mis direcciones
            </CustomCheckbox>
          </CustomFormItem>
          <CustomFormItem
            className='form-item-street'
            name='alias'
            rules={[
              { 
                required: saveAddressChecked, 
                message: 'Por favor, ingresa una etiqueta para la dirección' 
              }
            ]}
          >
            <CustomInput 
              placeholder='Etiqueta'
              value={aliasValue}
              onChange={handleAliasChange}
              disabled={(!saveAddressChecked && !isAddressSaved) || isAddressSaved}
            />
          </CustomFormItem>
        </>
      )}

      <Row className='form-buttons-container'>
        <Col md={12} xs={12}>
          <CustomButton 
            className='form-back-button' 
            onClick={() => {
              handleDeleteAddress();
              handleLoggedBackStep();
            }}
          >
            Anterior
          </CustomButton>
        </Col>
        <Col md={12} xs={12}>
          <CustomButton 
            className='form-confirm-button enabled-button margin-left-auto' 
            onClick={handleOkModal} 
            loading={isLoadingButton}
          >
            Salir
          </CustomButton>
        </Col>
      </Row>
    </>
  );
};