/** @format */

import React, { Component } from 'react';

import { formatPrice } from '../../../../../Hooks/Functions/FormatPrice';

import './style.scss';
import { ENV_COUNTRY } from '../../../../../Hooks/Variables/Enviroment';

export default class PriceDetailProduct extends Component {
	render() {
		return (
			<>
				{this.props.detail.pharmacy ? (
					<>
						{this.props.detail.interface && (
							<>
								{this.props.detail.interface.promotions ? (
									<>
										{/*<p className={this.props.detail.interface.promotions.price_promotion ? 'ph-product-price-before' : 'ph-product-price-normal'}>{formatPrice(this.props.detail.price)}</p>*/}
										<p className= 'ph-product-price-before' >{formatPrice(this.props.detail.price)}</p>
									</>
								) : (
									<>
										<p className="ph-product-price-normal">{formatPrice(this.props.detail.price)}</p>
									</>
								)}
							</>
						)}
					</>
				) : (
					<>
						{ENV_COUNTRY === 'CL' && <span className="ph-product-price-normal-from">Desde:</span> }
						<p className="ph-product-price-normal ph-product-price-normal-home">{formatPrice(this.props.detail.promotion_price ?? this.props.detail.min_price)}</p>
					</>
				)}
			</>
		);
	}
}
