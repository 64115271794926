import { Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import CustomImage from '../../../v2-components/Antd/Image';
import CustomCard from '../../../v2-components/Antd/Card';
import { handlePharmacy } from '../handle/pharmacy.handle';
import { handleMecanic } from '../handle/mecanic.handle';
import { handlePrice } from '../handle/price.handle';
import { handlePromotionPrice } from '../handle/promotion-price.handle';
import { handleLaboratory } from '../handle/laboratory.handle';
import { handleName } from '../handle/name.handle';
import { handleSince } from '../handle/since.handle';
import { handleOriginalImage } from '../handle/original-image.handle';
import { handleRecipeIcon } from '../handle/recipe-icon.handle';
import { handleTagIcon } from '../handle/tag-icon.handle';
import { image } from '../constant/image.constant';
import './style.scss';
/*Remove comment once favorites is working */
// import { FavoriteActions } from '../../../components/Commons/Favorites/Components';
import { ModalTypeRecipes } from '../../../components/Commons/Modals/ModalTypeRecipes';
import Button from '../../../components/Commons/Product/components/Button';
import { useHistory } from 'react-router-dom'
import { linkToDetailProduct } from '../../../components/Commons/Hooks/Functions/LinkToDetailProduct';
import { cenabastIconHandle } from '../handle/cenabast-icon.handle';


export default function CardProductMobile(props) {
	// console.log('CardProductMobile', props);
	const [imageSrc, setImageSrc] = useState(props.product.original_image);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const history = useHistory()

	const handleTypeRecipes = (event) => {
		event.stopPropagation()
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleImageError = () => {
		setImageSrc(image.default);
	};

	useEffect(() => {
		setImageSrc(props.product.original_image);
	}, [props.product.original_image]);

	const handleStopPropagation = (event) => {
		event.stopPropagation()
	}

	return (
		<>
			<CustomCard className="own-card-product-mobile" onClick={() => history.push(linkToDetailProduct(props.product))}>
				<Row justify="space-between" wrap={false}>
					<Col flex="none" className="own-image-icons-container-mobile">
						<Row className="own-tag-icon-container-mobile">{handleTagIcon(props.product, 26)}</Row>
						<Row justify="start" align="middle" className="own-image-mobile-container">
							<CustomImage src={handleOriginalImage(imageSrc)} width={75} onError={handleImageError} />
						</Row>
						<Row justify="start" className="own-recipe-image-icons-container" onClick={handleTypeRecipes}>
							{handleRecipeIcon(props.product, 24)}
							{cenabastIconHandle(props.product, 24, 'cenabast-icon')}
						</Row>
						<ModalTypeRecipes isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
					</Col>

					<Col flex="auto" className="own-favorite-container-mobile">

						<Row justify="space-between" align="bottom" onClick={handleStopPropagation}>
							{handleLaboratory(props.product, 'own-laboratory-label-mobile')}
							{/*Remove comment once favorites is working */}
							{/*<FavoriteActions item={props.product} listProductFavorite={props.listProductFavorite} />*/}
						</Row>

						<Row>{handleName(props.product, 'own-product-title-mobile', true)}</Row>
						<Row justify="start">{handlePharmacy(props.product, 'temporary-pharmacy-icon')}</Row>
						<Row justify="start">
							<Row justify="start">{handleSince(props.product, 'own-since-mobile')}</Row>
						</Row>
						<Row justify="end">
							<Row justify="center">{handleMecanic(props.product, 'own-mecanic-mobile')}</Row>
						</Row>
						<Row className="own-price-mobile-container">
							{handlePromotionPrice(props.product, 'own-promotion-price-mobile')}
							{handlePrice(props.product, 'own-final-price-mobile')}
						</Row>
						<Row justify="end" onClick={handleStopPropagation}><Button available={props.product.available} detail={props.product} /></Row>
					</Col>
				</Row>
			</CustomCard>
		</>
	);
}
