/** @format */

import { Col } from 'antd';
import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { ReactComponent as IconBag } from '../../../../../../../../v2-config/icons-svg/bag.svg';
import { ReactComponent as IconHome } from '../../../../../../../../v2-config/icons-svg/home.svg';
import { amountProductsCart } from '../../../../../../Hooks/Functions/AmountProductsCart';
import { ENV_COUNTRY } from '../../../../../../Hooks/Variables/Enviroment';
import './style.scss';

const UserActionsComponent = () => {
	const [isCartQuantity, setCartQuantity] = useGlobal('carQuantity');
	const history = useHistory();


	const handleBagLink = () => {
		history.push('/cart');
	};

	useEffect(() => {
		if (localStorage.getItem('userToken') || ENV_COUNTRY === 'CL') {
			setCartQuantity(amountProductsCart());
		} else {
			setCartQuantity(0);
		}
	}, [setCartQuantity]);

	return (
		<>
			<Col xs={15} sm={12} md={12} lg={6} xl={6} className="ph-user-actions-main-container">
				<div className="ph-user-actions-header-component">
					<NavLink className="ph-user-actions-link-container-home" to={`/`}>
						{console.log('pathname', window.location.pathname)}
						<IconHome
							className={window.location.pathname === '/' ? 'icons-theme-user-actions active' : 'icons-theme-user-actions'} />
					</NavLink>

					<div className="ph-user-actions-link-container" onClick={() => handleBagLink()}>
						<IconBag
							className={window.location.pathname === '/cart' ? 'icons-theme-user-actions active' : 'icons-theme-user-actions'} />

						<div className="ph-user-actions-baloon-icon">
							<h5	className="ph-user-actions-baloon-title">{isCartQuantity && <>{isCartQuantity > 9 ? '+9' : isCartQuantity}</>}</h5>
						</div>
					</div>
				</div>
			</Col>
		</>
	);
};
export default UserActionsComponent;
