/** @format */

import React from 'react'
import AwesomeSwiper from 'react-awesome-swiper'

// import CardRectangle from '../Product/Card/Rectangle'

import Boxes from '../BannerPromotions/components/Boxes'

import './style.scss'
import CardProductDesktop from '../../../v2-components/CardProduct/CardProductDesktop';

export default function Carousel(props) {
	const config = {
		swiperRef: null,
		spaceBetween: 0,
		loop: false,
		autoplay: {
			delay: 3000,
			stopOnLastSlide: false,
			disableOnInteraction: true,
		},
		preloadImages: false,
		lazy: true,
		speed: 500,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: props.carouselItems[0],
				spaceBetween: 0,
			},
			768: {
				slidesPerView: props.carouselItems[1],
				spaceBetween: 10,
			},
			640: {
				slidesPerView: props.carouselItems[2],
				spaceBetween: 0,
			},
			320: {
				slidesPerView: props.carouselItems[3],
				spaceBetween: 0,
			},
			250: {
				slidesPerView: props.carouselItems[4],
				spaceBetween: 0,
			},
		},
	}
	return (
		<>
			<AwesomeSwiper ref={(ref) => (config.swiperRef = ref)} config={config}>
				<div className='swiper-wrapper'>
					{props.type === 'product'
						? props.list.map(function (item, i) {
								return (
									<div className='swiper-slide' key={i}>
										{/*<CardRectangle detail={item} featured={props.featured} />*/}
										<CardProductDesktop product={item} featured={props.featured} />
									</div>
								)
						  })
						: props.list.map(function (item, i) {
								return (
									<div className='swiper-slide' key={i}>
										{props.domain === true ? (
											<Boxes detail={item} key={i} domain={props.domain} />
										) : (
											<Boxes detail={item} key={i} />
										)}
									</div>
								)
						  })}
				</div>
				<div className='swiper-button-prev ph-carousel-prev'></div>
				<div className='swiper-button-next ph-carousel-next'></div>
			</AwesomeSwiper>
		</>
	)
}
