import { Enviroment } from '../../../_config/constants/enviroment-v2.constant';
import CustomImage from '../../../v2-components/Antd/Image';
import { Icons } from '../constant/icons.constant';
import { Product } from '../constant/product.constant';
export const handleRecipeIcon = (product, width) => {
	console.log(product.prescription_type)
	// INICIO PARCHE
	// CONTEXTO: esta validacion se tiene que eliminar ya que la preescripcion debe venir como un atributo del producto
	if (product.prescription_type === Product.NP && product.master_category.toLowerCase() !== Product.auxSlug) {
		return;
	}
	// FIN PARCHE

	// if (product.prescription_type === Product.PM) {
	// 	return;
	// }
	if (width) {
		return <CustomImage width={width} src={Icons[Enviroment.COUNTRY][product.prescription_type]} />;
	}
	console.log('llego aca')
	return <CustomImage src={Icons[Enviroment.COUNTRY][product.prescription_type]} />;
};
