import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import CustomImage from '../../../../../v2-components/Antd/Image';
import CustomCard from '../../../../../v2-components/Antd/Card';

import { handleProductAdapter } from '../handle/product-adapter.handle';
import { handleStock } from '../handle/stock.handle';
import { handleMecanic } from '../handle/mecanic.handle';
import { handlePrice } from '../handle/price.handle';
import { handlePromotionPrice } from '../handle/promotion-price.handle';
import { handleLaboratory } from '../handle/laboratory.handle';
import { handleName } from '../handle/name.handle';
import { handleSince } from '../handle/since.handle';
import { handleTagIcon } from '../handle/tag-icon.handle';
import { handleOriginalImage } from '../handle/original-image.handle';
import { handleRecipeIcon } from '../handle/recipe-icon.handle';
import { image } from '../constant/image.constant';
import './style.scss';

export default function CardProductDesktop(props) {
	const product = handleProductAdapter(props.product);
	const [imageSrc, setImageSrc] = useState(product.original_image);

	// const handleFavorite = () => {
	// 	console.log('Agregado a favoritos');
	// };
	useEffect(() => {
		setImageSrc(product.original_image);
	}, [product.original_image]);

	const handleImageError = () => {
		setImageSrc(image.default);
	};

	return (
		<>
			<CustomCard className="own-card-product-desktop-landing" onClick={props.onClickProduct}>
				<Row gutter={4} className="own-image-icons-container">
					<Col span={4} className="own-card-icons-container">
						{handleTagIcon(product)}
						<div className="own-card-icons-sub-container">{handleRecipeIcon(product)}</div>
					</Col>
					<Col span={16}>
						<Row gutter={16}>
							<CustomImage src={handleOriginalImage(imageSrc)} width={150} onError={handleImageError} />
						</Row>
					</Col>
				</Row>
				<Row justify="start">{handleLaboratory(product, 'own-laboratory-label')}</Row>
				<Row justify="center">{handleName(product, 'own-product-title')}</Row>
				<Row justify="start">{handleStock(product, 'own-stock')}</Row>
				<Row className="own-promotion-prices-container">
					<Row justify="start">{handleSince(product, 'own-since')}</Row>
					<Row justify="center">{handleMecanic(product, 'own-mecanic')}</Row>
					<Row className="own-price-container">
						{handlePromotionPrice(product, 'own-promotion-price')}
						{handlePrice(product, 'own-final-price')}
					</Row>
				</Row>
			</CustomCard>
		</>
	);
}
