/** @format */

import React, { Component } from 'react'

import { formatPrice } from '../../../../../Hooks/Functions/FormatPrice'
import './style.scss'

export default class PromotionProductDiscount extends Component {
	render() {
		return (
			<>
				<div className='ph-product-price-promotion'>

					{this.props.promotion.interface.promotions.description_promotion && (
						// <>{this.props.promotion.interface.promotions.description_promotion}</>
						<>
							{formatPrice(this.props.promotion.promotion_price)}

						</>
					)}
					{this.props.promotion.interface.promotions.percentaje_promotion && (
						// <>{this.props.promotion.interface.promotions.percentaje_promotion}</>
						<>
							{formatPrice(this.props.promotion.promotion_price)}

						</>
					)}
					{this.props.promotions?.rules.tag &&
						<>{this.props.promotions.rules.tag}</>
					}
					{this.props.promotion.interface.promotions.price_promotion && (
						<>
							{/* <div className='ph-product-price-promotion-symbol'>
								{this.props.promotion.price_promotion.symbol}
							</div> */}
							{formatPrice(this.props.promotion.interface.promotions.price_promotion.amount)}

						</>
					)}
				</div>
			</>
		)
	}
}
