/** @format */

import React, { useState } from 'react';
import { ENV_COUNTRY } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
import { Row, Col, Form, Select, Input } from 'antd';
import CountryValidator from '../../../../../../components/Commons/CountryValidator';
import { rulesValidationMask } from '../../../../../../components/Commons/InputMask/rules';
import InputPhone from '../../../../../../components/Commons/InputCountry/Phone';
import InputRut from '../../../../../../components/Commons/InputCountry/Rut';
import InputField from '../../../../../../components/Commons/Input';
import InputMask from '../../../../../../components/Commons/InputMask';
import TypeHouseSelect from './TypeHouseSelect';
import AditionalForm from './AditionalForm';

import { Country } from './country';
import './style.scss';

export default function CheckoutPersonalData(props) {
	console.log('props',props)
	const [date] = useState(new Date());
	return (
		<>
			<div className="ph-withdrawal-personal-data-global-container">
				<h3 className="ph-withdrawal-personal-data-title">Datos de quien recibirá la entrega</h3>
				<Row>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
						<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'first_name'} inputNameLabel={'Nombre'} inputNameType={'text'} inputNameRules={'rulesFirstName'} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
						<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'last_name'} inputNameLabel={'Apellido'} inputNameType={'text'} inputNameRules={'rulesLastName'} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
						<InputField
							className={'ph-withdrawal-personal-data-field-input'}
							inputName={'email'}
							inputNameLabel={'Correo electrónico'}
							inputNameType={'text'}
							inputNameRules={'rulesEmail'}
							disabled={Country[ENV_COUNTRY].handleDisabledEmail()}
						/>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
						<InputPhone classInput={'ph-withdrawal-personal-data-field-input'} />
					</Col>
					<CountryValidator
						countries={['CL']}
						component={
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<Form.Item
									name="sex"
									rules={[
										{
											required: true,
											message: 'Introduzca su Género',
										},
									]}
								>
									<Select placeholder="Género" className="ph-withdrawal-personal-data-field-selector" size="large" style={{ width: '100%' }}>
										<Select.Option value="Masculino">Masculino</Select.Option>
										<Select.Option value="Femenino">Femenino</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						}
					/>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
						<InputRut classInput={'ph-withdrawal-personal-data-field-input'} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
						<Form.Item name="age" rules={rulesValidationMask.rulesAge(date)}>
							<InputMask className="ph-withdrawal-personal-data-field-input" mask="99/99/9999" placeholder="31/12/2021" />
						</Form.Item>
					</Col>
					{ENV_COUNTRY === 'MX' && (
						<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
							<Form.Item name="curp" rules={rulesValidationMask.rulesCURP}>
								<InputMask className={'ph-withdrawal-personal-data-field-input'} mask="aaaa999999aaaaaa*9" placeholder="CURP" />
							</Form.Item>
						</Col>
					)}
				</Row>
				{ENV_COUNTRY === 'MX' && (
					<>
						<h3 className="ph-withdrawal-personal-data-title">Dirección personal:</h3>
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'colony'} inputNameLabel={'Colonia'} inputNameType={'text'} inputNameRules={'rulesRequired'} />
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-delivery-form-container">
								<InputField
									className={'ph-withdrawal-personal-delivery-field-input'}
									inputName={'municipality'}
									inputNameLabel={'Municipio'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									disabled={false}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField
									className={'ph-withdrawal-personal-delivery-field-input'}
									inputName={'street_mx'}
									inputNameLabel={'Calle'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
									disabled={false}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField
									className={'ph-withdrawal-personal-delivery-field-input'}
									inputName={'street_number_mx'}
									inputNameLabel={'No. de calle'}
									inputNameType={'text'}
									inputNameRules={'rulesRequired'}
								/>
							</Col>
							<TypeHouseSelect personalData={props.personalData} mx />
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'city'} inputNameLabel={'Ciudad'} inputNameType={'text'} inputNameRules={'rulesRequired'} />
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'province'} inputNameLabel={'Estado'} inputNameType={'text'} inputNameRules={'rulesRequired'} />
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'zip_code'} inputNameLabel={'Código Postal'} inputNameType={'text'} inputNameRules={'rulesRequired'} />
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField className={'ph-withdrawal-personal-data-field-input'} inputName={'between_street'} inputNameLabel={'Calle referencial'} inputNameType={'text'} />
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-data-form-container">
								<InputField className={'ph-withdrawal-personal-delivery-field-input'} inputName={'reference_mx'} inputNameLabel={'Referencia'} inputNameType={'text'} />
							</Col>
						</Row>
					</>
				)}
			</div>
			{(props.isRetirementData.locals_group.some((element) => element.type_retirement === 'domicilio') || ENV_COUNTRY === 'CO') && (
				<div className="ph-withdrawal-personal-delivery-global-container">
					<h3 className="ph-withdrawal-personal-delivery-main-title">Datos para despacho:</h3>
					<Row>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-delivery-form-container">
							<Form.Item name="commune">
								<Input className="ph-withdrawal-personal-delivery-field-input" size="large" type="text" placeholder="Comuna" disabled />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-delivery-form-container">
							<InputField
								className={'ph-withdrawal-personal-delivery-field-input'}
								inputName={'street'}
								inputNameLabel={'Calle'}
								inputNameType={'text'}
								inputNameRules={'rulesFirstName'}
								disabled={false}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} className="ph-withdrawal-personal-delivery-form-container">
							<InputField
								className={'ph-withdrawal-personal-delivery-field-input'}
								inputName={'street_number'}
								inputNameLabel={'No. de calle'}
								inputNameRule={true}
								inputNameMessage={'Ingrese su No. de calle'}
								inputNameType={'text'}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<TypeHouseSelect personalData={props.personalData} />
						<AditionalForm />
						<Col span={24} className="ph-withdrawal-personal-delivery-form-container">
							<InputField className={'ph-withdrawal-personal-delivery-field-input'} inputName={'reference'} inputNameLabel={'Referencia'} inputNameRule={false} inputNameType={'text'} />
						</Col>
					</Row>
				</div>
			)}
		</>
	);
}
