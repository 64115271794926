import CustomImage from '../../../v2-components/Antd/Image';

export const handlePharmacy = (product, className) => {
	if (!product.pharmacy) {
		return <label>{'   '}</label>;
	}

	if (product.pharmacy_image) {
		return (
			<>
				<label className="own-product-pharmacy-mobile">
					<CustomImage className={className} src={product.pharmacy_image} /> <span>Farmacias {product.pharmacy}</span>
				</label>
			</>
		);
	}

	return <label className="own-product-pharmacy-mobile">Farmacias {product.pharmacy}</label>;
};
