import { Row } from 'antd';
import GoogleMapReact from 'google-map-react';
import { ENVIROMENT } from '../../../../../../../v2-config/constants/enviroment.constant'; 
import CustomImage from '../../../../../../../v2-components/Antd/Image';
import './style.css';

export default function GoogleMap(props) {
	const GoogleMapsMarker = () => (
		<Row className={'google-image-container'}>
			<CustomImage
				src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
				width={30}
				height={30}
				style={{ position: 'relative', zIndex: 2 }}
			/>
		</Row>
	);
	return (
		<Row className={'main-container'}>
			<GoogleMapReact bootstrapURLKeys={{ key: ENVIROMENT.GOOGLE_MAPS_KEY }} center={props?.coordinates} zoom={props?.zoom || 17}>
				<GoogleMapsMarker lat={props?.coordinates?.lat} lng={props?.coordinates?.lng} />
			</GoogleMapReact>
		</Row>
	);
}
