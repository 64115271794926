import React from 'react';
import { Row, Col } from 'antd';
import CustomFormItem from '../../../../../../../../../v2-components/Antd/FormItem';
import CustomSelect from '../../../../../../../../../v2-components/Antd/Select';
import CustomInput from '../../../../../../../../../v2-components/Antd/Input';
import CustomButton from '../../../../../../../../../v2-components/Antd/Button';
import { locationLabel } from '../../handle/location-label.handle';

export const StepOne = ({
  form,
  isLogged,
  userAddressList = [],
  selectedUserAlias,
  selectedRegion,
  selectedCommune,
  directionValue,
  handleUserAddressChange,
  handleRegionChange,
  handleCommuneChange,
  handleDirectionChange,
  handleGetAddress,
  isLoadingButton,
  disableContinueButton,
  regionOptions,
  communeOptions,
  formattedAddressOptions,
  isAddressListItemSelected
}) => {
  return (
    <>
      <h4 className='ph-address-modal-form-title'>
        Podrás ver la disponibilidad de entrega y calcular la distancia a tu farmacia más cercana.
      </h4>
      
      {isLogged && userAddressList.length > 0 && (
        <CustomFormItem 
          label="Elige entre tus direcciones o agrega una nueva dirección"
          name="addressList" 
          labelCol={{ span: 24 }} 
					wrapperCol={{ span: 24 }} 
        >
          <CustomSelect 
            placeholder='Elegir dirección guardada' 
            onChange={handleUserAddressChange}
            value={selectedUserAlias}
            options={formattedAddressOptions}
          />
        </CustomFormItem>
      )}

      <Row className='ph-address-modal-form-region-container' gutter={[10, 0]}>
        <Col md={12} xs={24}>
          <CustomFormItem 
            label={`${locationLabel('region')}`}
            labelCol={{ span: 24 }} 
            wrapperCol={{ span: 24 }} 
            className="ph-address-form-item"
            rules={[{ required: true, message: 'Por favor, ingresa la región' }]}
            name="region" 
          >
            <CustomSelect 
              showSearch
              placeholder='Elegir región' 
              onChange={handleRegionChange}
              value={selectedRegion}
              options={regionOptions}
              disabled={isAddressListItemSelected}
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem 
            label={`${locationLabel('commune')}`}
            name="commune"
            labelCol={{ span: 24 }} 
						wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'Por favor, ingresa la comuna' }]}
          >
            <CustomSelect
              showSearch
              placeholder='Elegir comuna' 
              onChange={handleCommuneChange}
              value={selectedCommune} 
              options={communeOptions}
              disabled={isAddressListItemSelected || !selectedRegion}
            />
          </CustomFormItem>
        </Col>
      </Row>

      <CustomFormItem
        className='form-item-street'
        name='direction'
        label='Calle y número'
        labelCol={{ span: 24 }} 
				wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: 'Por favor, ingresa la calle y número' }]}
      >
        <CustomInput 
          placeholder='Calle y número'
          value={directionValue}
          onChange={handleDirectionChange}
          disabled={isAddressListItemSelected}
        />
      </CustomFormItem>

      <span className='form-required-span text-align'>
        *Datos obligatorios.
      </span>

      <Row className='form-buttons-container'>
        <Col md={24} xs={24}>
          <CustomButton 
            className={`form-confirm-button ${disableContinueButton ? '' : 'enabled-button'}`} 
            disabled={disableContinueButton} 
            onClick={handleGetAddress}
            loading={isLoadingButton}
          >
            Continuar
          </CustomButton>
        </Col>
      </Row>
    </>
  );
};