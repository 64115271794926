import { fromAddress, setDefaults } from 'react-geocode';
import { ENVIROMENT } from '../../../../../../v2-config/constants/enviroment.constant'; 
import { constant } from '../constant/config.constant';

setDefaults({
	key: ENVIROMENT.GOOGLE_MAPS_KEY,
	language: 'en',
	region: 'es',
});

export const geocodeAddress = async (address = constant.defaultAddress) => {
	try {
		const { results } = await fromAddress(address);
		console.log('results: ', results);
		if (results[0].partial_match === true) {
			throw new Error('Dirección inexistente');
		}
		const { lat, lng } = results[0].geometry.location;
		const formatLat = Number(parseFloat(lat).toFixed(6));
		const formatLng = Number(parseFloat(lng).toFixed(6));
		return {
			lat: formatLat,
			lng: formatLng,
		};
	} catch (error) {
		throw error;
	}
};
