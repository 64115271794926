export const Icons = {
	AR: {
		promotion_tag: 'https://storage.googleapis.com/tirx-resources-develop/1e20fb76-f295-4f3f-871c-eec88859b6ed.png',
		NP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
		RMRSCS: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
		WP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
	},
	CL: {
		promotion_tag: 'https://storage.googleapis.com/tirx-resources-develop/1e20fb76-f295-4f3f-871c-eec88859b6ed.png',
		NP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
		RMRSCS: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
		MRE: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
		APR: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',


	},
	MX: {
		promotion_tag: 'https://storage.googleapis.com/tirx-resources-develop/1e20fb76-f295-4f3f-871c-eec88859b6ed.png',
		NP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Venta%20directa.png',
		RMRSCS: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20retenido%20sin%20control%20de%20stock.png',
		WP: 'https://storage.googleapis.com/master-image-bucket/condicion-venta/Receta%20me%CC%81dica.png',
	},
};
