import { Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import CustomImage from '../../../../../v2-components/Antd/Image';
import CustomCard from '../../../../../v2-components/Antd/Card';

import { handleProductAdapter } from '../handle/product-adapter.handle';

import { handlePharmacy } from '../handle/pharmacy.handle';
import { handleMecanic } from '../handle/mecanic.handle';
import { handlePrice } from '../handle/price.handle';
import { handlePromotionPrice } from '../handle/promotion-price.handle';
import { handleLaboratory } from '../handle/laboratory.handle';
import { handleName } from '../handle/name.handle';
import { handleSince } from '../handle/since.handle';
import { handleOriginalImage } from '../handle/original-image.handle';
import { handleRecipeIcon } from '../handle/recipe-icon.handle';
import { handleTagIcon } from '../handle/tag-icon.handle';
import { image } from '../constant/image.constant';
import './style.scss';

export default function CardProductMobile(props) {
	console.log('CardProductMobile', props);
	const product = handleProductAdapter(props.product);
	const [imageSrc, setImageSrc] = useState(product.original_image);


	const handleImageError = () => {
		setImageSrc(image.default);
	};
	useEffect(() => {
		setImageSrc(product.original_image);
	}, [product.original_image]);

	return (
		<>
			<CustomCard className="own-card-product-mobile-landing" onClick={props.onClickProduct}>
				<Row justify="space-between" wrap={false}>
					<Col flex="none" className="own-image-icons-container-mobile">
						<Row className="own-tag-icon-container-mobile">{handleTagIcon(product, 26)}</Row>
						<Row justify="start" align="middle" className="own-image-mobile-container">
							<CustomImage src={handleOriginalImage(imageSrc)} width={75} onError={handleImageError} />
						</Row>
						<Row justify="start" className="own-recipe-image-icons-container">
							{handleRecipeIcon(product, 24)}
						</Row>
					</Col>

					<Col flex="auto" className="own-favorite-container-mobile">
						<Row justify="space-between" align="bottom">
							{handleLaboratory(product, 'own-laboratory-label-mobile')}
						</Row>
						<Row>{handleName(product, 'own-product-title-mobile', true)}</Row>
						<Row justify="start">{handlePharmacy(product, 'temporary-pharmacy-icon')}</Row>
						<Row justify="start">
							<Row justify="start">{handleSince(product, 'own-since-mobile')}</Row>
						</Row>
						<Row justify="end">
							<Row justify="center">{handleMecanic(product, 'own-mecanic-mobile')}</Row>
						</Row>
						<Row className="own-price-mobile-container">
							{handlePromotionPrice(product, 'own-promotion-price-mobile')}
							{handlePrice(product, 'own-final-price-mobile')}
						</Row>
					</Col>
				</Row>
			</CustomCard>
		</>
	);
}
