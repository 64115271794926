import React from 'react';
import { StepOne } from './Steps/StepOne';
import { StepTwo } from './Steps/StepTwo';
import { StepThree } from './Steps/StepThree';

export const NoLoggedSteps = ({ currentStep, ...stepProps }) => {
  const steps = [
    <StepOne 
      {...stepProps}
      isLogged={false}
      userAddressList={[]}
    />,
    <StepTwo 
      {...stepProps}
      isLogged={false}
    />,
    <StepThree 
      {...stepProps}
      isLogged={false}
    />
  ];

  return steps[currentStep] || null;
};