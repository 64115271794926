import { AxiosAdapter } from '../../../../../../_config/v2/adapters/axios/axios.adapter';
import { Http } from '../../../../../../_config/v2/adapters/axios/axios.protocols';
import { ENVIROMENT } from '../../../../../../v2-config/constants/enviroment.constant';

export class Service {
    async getRegionsByCountry() {
        return new AxiosAdapter({
            method: Http.GET,
			url: `${ENVIROMENT.FUNCTION_MIGRATION_PHAROL}/place/country/${ENVIROMENT.COUNTRY}`,
		})
        .request()
        .then((response) => {
            return response;
        })
			.catch((e) => {
				throw e;
			});
	}

	async getCommunesByRegion(region) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${ENVIROMENT.FUNCTION_MIGRATION_PHAROL}/place/country/${ENVIROMENT.COUNTRY}/region/${region}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}


	async getUserdAddress (userToken) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${ENVIROMENT.FUNCTION_API_PHARMACY}/user/get-me`,
			headers: {
				Authorization: `Bearer ${userToken}`,
			},
		})
			.request()
			.then((response) => {
				return response.data.address;
			})
			.catch((e) => {
				throw e;
			});
	}

	async saveAddress(userToken, updatedAddressList){
		return new AxiosAdapter({
			method: Http.POST,
			url: `${ENVIROMENT.FUNCTION_API_PHARMACY}/user/add-address`,
			data: {
				address: updatedAddressList
			},
			headers: {
				Authorization: `Bearer ${userToken}`,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}