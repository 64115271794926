import { ENVIROMENT } from "../../../../../../../../v2-config/constants/enviroment.constant";

export const locationLabel = (field) => {
    const labels = {
      CL: {
        region: 'Región',
        commune: 'Comuna'
      },
      AR: {
        region: 'Provincia',
        commune: 'Localidad'  
      },
      MX: {
        region: 'Entidad federativa',
        commune: 'Asentamiento'
      },
      CO: {
        region: 'Departamento', 
        commune: 'Ciudad'
      }
    };
  
    return labels[ENVIROMENT.COUNTRY]?.[field] || field;
  };