/** @format */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { setGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';

import { GlobalConsumer } from '../../../../components/Commons/Context/Global';
import { BagConsumer } from '../../../../components/Commons/Context/Bag';

import { Row, Col, Form } from 'antd';

import MetaDescription from '../../../../components/Commons/MetaDescription';
import Notify from '../../../../components/Commons/Notify';

import CheckoutTimeLine from '../../../../components/CheckoutTimeLine';
import CheckoutGlobalTitle from '../../../../components/CheckoutGlobalTitle';

import { RemoveLocalStorage } from '../../../../components/HooksCheckout/Functions/RemoveLocalStorage';

import BagMainInfo from '../../components/BagMainInfo';
import BagMainInfoMobile from '../../components/BagMainInfoMobile';
import BagProductsGroup from '../../components/BagProductsGroup';
import BagError from '../../components/BagError';
import BagModalCalculate from '../../components/BagModalCalculate';

import { BagValidateDelivery } from '../../functions/BagValidateDelivery';
import { BagCalculation } from '../../functions/BagCalculation';
import { BagMerge } from '../../functions/BagMergeLocals';

import BagTotal from '../../functions/BagTotal';

import BagAddress from '../../components/BagMainInfo/components/BagAddress';
import { CheckUserInfo } from '../../../../components/Commons/Hooks/Functions/CheckUserInfo';

import './style.scss';
import ScrollToTop from '../../../../v2-components/ScrollToTop';

export default function Bag() {
	const { t } = useTranslation('global');
	const { isBag, isMobile, setBag, isLocation } = GlobalConsumer();
	const { isBagData, isBagCollapse, setBagData, setBagRetirement, setBagCalculateModal, setBagRetirementPharmacies, setBagTotalCart } = BagConsumer();
	const [isOnDemand] = useState(JSON.parse(localStorage.getItem('onDemandData')));
	const [isType, setType] = useState(null);
	const [cartForm] = Form.useForm();
	const { handleCheckRetirement } = BagValidateDelivery();
	const { handleBagTotal } = BagTotal();
	const history = useHistory();


	useEffect(() => {
		RemoveLocalStorage();
		async function fetchData() {
			const login = await CheckUserInfo(localStorage.getItem('userMainInfo'));
			if (login === true) {
				history.push('/cart');
			} else {
				history.push('/profile/account-data');
			}
		}
		fetchData();
	}, [history]);

	useEffect(() => {
		if (isBag.length > 0) {
			setBagCalculateModal(true);
			BagCalculation('farmacia', false).then(async (response) => {
				setBagCalculateModal(false);
				if (response) {
					const localsMerged = await BagMerge(response.locals_group);
					const responseBag = { ...response, locals_group: localsMerged };

					let checkRetirement = handleCheckRetirement(isBag);
					setBagTotalCart(handleBagTotal(responseBag));
					let pharmacies = {};
					responseBag.locals_group.forEach((item) => {
						if (item.payment.discount !== 0) {
							Notify({
								title: `¡Bien!`,
								colorTitle: 'ph-main-notification-warning-title',
								description: `Estás disfrutando de un descuento en ${item.name_editable}. El monto se refleja al seleccionar el tipo de entrega.`,
								time: 5,
								image: 'warning',
								placement: 'topRight',
							});
						}
						pharmacies = {
							...pharmacies,
							[item.name]: item?.locals.length > 0 ? item : false,
						};
						if (pharmacies[item.name] === false) {
							checkRetirement[item.name] = {
								info: [],
								deliveryStatus: false,
								type_retirement: null,
							};
							let bag = isBag;
							bag.forEach((element) => {
								if (item.name === element.pharmacy) delete element.type_retirement;
							});
							console.log('bag', bag);
							localStorage.setItem('cart', JSON.stringify(bag));
							setBag(bag);
						}
					});
					if (isOnDemand) {
						localStorage.setItem('locationStore', JSON.stringify(isOnDemand.location));
						setGlobal({ address: isOnDemand.location, onDemandCart: true });
						setType('on_demand');
					}
					setBagRetirement({ ...checkRetirement });
					setBagRetirementPharmacies(pharmacies);
					setBagData(responseBag);
					if (isLocation.default) {
						//setTimeout(() => {
						setGlobal(() => ({ GlobalModalSuggestion: true }));
						//}, 4000);
					}
				} else setBagData(false);
			});
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleCheckRetirement, setBagRetirementPharmacies, setBagCalculateModal, setBagData, setBagRetirement, cartForm]);

	if (isBag.length <= 0)
		return (
			<BagError
				data={{
					title: '¡Tu Bolsa está triste!',
					description: '...aún no agregas ningún producto.',
				}}
			/>
		);

	if (isBagData === null)
		return (
			<div className="ph-bag-loading-global-container">
				<BagModalCalculate />
			</div>
		);

	if (isBagData === false)
		return (
			<BagError
				data={{
					title: '¡Ha ocurrido un error!',
					description: 'Comuníquese con nuestro personal.',
				}}
			/>
		);

	return (
		<>
		<ScrollToTop/>
			<MetaDescription title={t('main.metaTitle')} name={'description'} content={t('main.metaContent')} />
			<div className="ph-bag-global-container">
				<div className="ph-bag-main-container">
					<CheckoutTimeLine />
					{isMobile && (
						<div className="ph-bag-main-info-main-address-title-container">
							<div className="ph-bag-main-info-main-title-container">
								<span className="ph-bag-main-info-main-title-dot"></span>
								<span className="ph-bag-main-info-main-title">¿Cuál es tu dirección de entrega?</span>
							</div>
							<div>
								<BagAddress isType={isType} classType={'ph-cart-form-address'} />
							</div>
						</div>
					)}
					<div className="ph-bag-main-title-container">
						<CheckoutGlobalTitle image={''} title={'Tu Bolsa'} />

						{/* <div className="ph-bag-countdown-static-container">
							<ClockCircleOutlined className="ph-bag-countdown-static-icon" />
							<span className="ph-bag-countdown-static-description">5:00</span>
						</div> */}
					</div>
					<Row>
						<Col xs={24} sm={24} md={24} lg={17} xl={17}>
							<BagProductsGroup cartForm={cartForm} />
						</Col>
						<Col xs={24} sm={24} md={24} lg={7} xl={7}>
							<div
								className={`${isBagCollapse ? 'ph-bag-main-info-box-collapsed' : 'ph-bag-main-info-box-collapse'} ph-bag-right-side-container`}
								style={{ display: `${isBagData.products.some((prod) => prod.prescription_type !== 'NP') || !isMobile ? '' : 'none'}` }}
							>
								<BagMainInfo isType={isType} cartForm={cartForm} />
							</div>

							{isMobile && <BagMainInfoMobile cartForm={cartForm} />}
						</Col>
					</Row>
				</div>
			</div>
			<BagModalCalculate />
		</>
	);
}
