import React from 'react';
import { Row, Col } from 'antd';
import CustomFormItem from '../../../../../../../../../v2-components/Antd/FormItem';
import CustomInput from '../../../../../../../../../v2-components/Antd/Input';
import CustomButton from '../../../../../../../../../v2-components/Antd/Button';
import CustomCheckbox from '../../../../../../../../../v2-components/Antd/Checkbox';

export const StepTwo = ({
  newAddress,
  isLogged,
  isAddressListItemSelected,
  saveAddressChecked,
  handleSaveAddress,
  aliasValue,
  handleAliasChange,
  handleLoggedBackStep,
  handleConfirmAddress,
  isLoadingButton,
  handleLogin
}) => {
  return (
    <>
      <h4 className='ph-address-modal-form-title'>
        ¿Es ésta tu dirección?
      </h4>

      <h5 className='ph-address-modal-form-sub-title'>
        Dirección ingresada
      </h5>

      <p className='form-address-modal-text-address'>
        {newAddress?.formFields}
      </p>

      {!isAddressListItemSelected && (
        <>
          {isLogged ? (
            <>
              <CustomFormItem
                name='saveAddress'
                className='form-item-street'
              >
                <CustomCheckbox
                  checked={saveAddressChecked}
                  onChange={handleSaveAddress}
                >
                  Guardar en mis direcciones
                </CustomCheckbox>
              </CustomFormItem>
              <CustomFormItem
                className='form-item-street'
                name='alias'
                rules={[
                  { 
                    required: saveAddressChecked, 
                    message: 'Por favor, ingresa una etiqueta para la dirección' 
                  }
                ]}
              >
                <CustomInput 
                  placeholder='Etiqueta'
                  value={aliasValue}
                  onChange={handleAliasChange}
                  disabled={!saveAddressChecked}
                />
              </CustomFormItem>
            </>
          ) : (
            <span className='form-required-span text-align'>
              Para guardar tu dirección para futuras compras <CustomButton className='form-link-button' type="link" onClick={handleLogin}>Inicia sesión</CustomButton>
            </span>
          )}
        </>
      )}

      <div className='span-container'>
        <span className='form-required-span'>
          Al editar tu dirección, puede que haya farmacias fuera de rango.
          Antes de confirmar, verifica en el mapa. 
        </span>
      </div>

      <Row className='form-buttons-container'>
        <Col md={12} xs={12}>
          <CustomButton className='form-back-button' onClick={handleLoggedBackStep}>
            Anterior
          </CustomButton>
        </Col>
        <Col md={12} xs={12}>
          <CustomButton 
            className='form-confirm-button enabled-button margin-left-auto' 
            onClick={handleConfirmAddress}
            loading={isLoadingButton}
          >
            Continuar
          </CustomButton>
        </Col>
      </Row>
    </>
  );
};