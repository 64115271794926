/** @format */

import React from 'react';
import Images from '../Commons/Images';
import bagIcon from '../Commons/Assets/Icons/Filled/Enabled/bag.svg';
import locationIcon from '../Commons/Assets/Icons/Filled/Enabled/tracking.svg';

import './style.scss';

export default function TitleBox(props) {
	const handleRenderImage = (item) => {
		if (item === 'bag') {
			return bagIcon;
		} else if (item === 'tracking') {
			return locationIcon;
		}
		return null;
	};
	console.log(props);
	return (
		<div className="ph-checkout-title-main-container">
			{handleRenderImage(props.image) && (<Images
				classContent={'ph-checkout-title-image-container'}
				classImage={`${props.image === 'bag' ? 'ph-checkout-title-image-bag' : 'ph-checkout-title-image'}`}
				default={handleRenderImage(props.image)}
				title={props.title}
			/>
			)}
			<div className="ph-checkout-title-container">
				<h1 className="ph-checkout-title">{props.title}</h1>
			</div>
		</div>
	);
}
