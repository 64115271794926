import CustomImage from "../../../v2-components/Antd/Image";



export const cenabastIconHandle = (product, width, className) => {

	if (width) {
		return <CustomImage width={width} src={product.cenabast_image}  className={className}/>;
	}

	if (product.cenabast) {
		return <CustomImage src={product.cenabast_image}/>
	}

	return null

};
