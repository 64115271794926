/** @format */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal, setGlobal } from 'reactn';
import { Row, Col, Button, Input } from 'antd';
import { ENV_COUNTRY, ENV_IMG_ROUTE } from '../../../../../../components/Commons/Hooks/Variables/Enviroment';
// import { DeleteFilled, RightOutlined, LeftOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { BagConsumer } from '../../../../../../components/Commons/Context/Bag';
import { GlobalConsumer } from '../../../../../../components/Commons/Context/Global';
import { formatPrice } from '../../../../../../components/Commons/Hooks/Functions/FormatPrice';
import { handleLocals } from '../../../../functions/BagCalculation/components/BagLocals';
import { Calculation } from '../../../../functions/BagCalculation/services';
import { BagMergeLocals } from '../../../../functions/BagMergeLocals';
import { handleCountPharmacies } from '../../../../functions/BagCountPharmacies';
import { GetUserData } from '../../../../../../components/Commons/Hooks/Functions/GetUserData';
import Images from '../../../../../../components/Commons/Images';
import Notify from '../../../../../../components/Commons/Notify';
import Promotions from '../../../../../../components/Commons/Product/components/Promotions';
import Price from '../../../../../../components/Commons/Product/components/Price';
import BagAddProduct from '../../../../functions/BagAddProduct';
import BagDeleteQuantityProduct from '../../../../functions/BagDeleteQuantityProduct';
import BagDeleteProduct from '../../../../functions/BagDeleteProduct';
import BagRecipeFilter from '../../../../functions/BagRecipeFilter';
import BagTotal from '../../../../functions/BagTotal';
import BagAddProductElectronic from '../../../../functions/BagAddProductElectronic';
import servicesPBM from './components/BagPBM/services';
import BagPBM from './components/BagPBM';
import BagTypePrescription from './components/BagTypePrescription';
// import BagAlternatives from './components/BagAlternatives';
import CountryValidator from '../../../../../../components/Commons/CountryValidator';
import { ModalTypeRecipes } from '../../../../../../components/Commons/Modals/ModalTypeRecipes';
import './style.scss';

export default function BagProducts(props) {
	const { isLocation, isLoginUser, setBag } = GlobalConsumer();
	const {
		isBagData,
		setBagData,
		setBagRecipe,
		setBagFilterProducts,
		setBagTotalCart,
		isBagRetirementPharmacies,
		isBagDeliveryPharmacies,
		setBagRetirementPharmacies,
		setBagDeliveryPharmacies,
		isBagRetirement,
		setBagRetirement,
		setLoadingGroup,
		isBagRequest,
		setBagRequest,
	} = BagConsumer();

	const { handleAddProduct } = BagAddProduct();
	const { handleDeleteQuantityProduct } = BagDeleteQuantityProduct();
	const { handleDeleteProduct } = BagDeleteProduct();
	const { handleBagTotal } = BagTotal();
	const { handleDeleteProductFromCartElectronic } = BagAddProductElectronic();

	const [isCovenantsPBM] = useGlobal('pbmCheck');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleVerifyVoucherFields = () => {
		const fields = props.cartForm.getFieldsValue(true);
		for (const clave in fields) {
			if (!fields[clave]) delete fields[clave];
		}
		return fields;
	};

	const handleAddQuantity = async (cart, product, quantity) => {
		if (product.pbm_data) {
			Notify({
				title: `¡Advertencia!`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Tienes un convenio PBM asociado a este producto, si deseas modificar la cantidad, debes eliminar primero la adjudicacion.',
				time: 3,
				image: 'warning',
				placement: 'topRight',
			});
			return;
		}
		setBagRequest(true);
		const products = await handleAddProduct(cart, product, quantity);
		if (products) {
			let filterProducts = products.filter((item) => item.pharmacy === product.pharmacy);
			setLoadingGroup((prevState) => ({
				...prevState,
				[props.name]: true,
			}));
			let localGroupPharmacy;

			let userData;
			if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);

			const voucherPharmacies = handleVerifyVoucherFields();

			await Calculation(
				filterProducts,
				isLocation,
				isLoginUser.email,
				isLoginUser.token,
				voucherPharmacies,
				handleLocals(filterProducts),
				'domicilio',
				userData,
				false,
				handleCountPharmacies(),
			).then(async (response) => {
				let responseService;
				if (response?.locals_group?.length > 1) {
					const localsGroup = await BagMergeLocals(response.locals_group);
					responseService = { ...response, locals_group: [localsGroup] };
				} else responseService = response;

				let deliveryPharmacies = isBagDeliveryPharmacies;
				deliveryPharmacies[filterProducts[0].pharmacy] =
					responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
				setBagDeliveryPharmacies({ ...deliveryPharmacies });
			});

			await Calculation(
				filterProducts,
				isLocation,
				isLoginUser.email,
				isLoginUser.token,
				voucherPharmacies,
				handleLocals(filterProducts),
				'farmacia',
				userData,
				false,
				handleCountPharmacies(),
			).then(async (response) => {
				let responseService;
				if (response?.locals_group?.length > 1) {
					const localsGroup = await BagMergeLocals(response.locals_group);
					responseService = { ...response, locals_group: [localsGroup] };
				} else responseService = response;

				localGroupPharmacy = response.locals_group[0];
				let retirementPharmacies = isBagRetirementPharmacies;
				retirementPharmacies[filterProducts[0].pharmacy] =
					responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
				setBagRetirementPharmacies({ ...retirementPharmacies });
			});

			const validRetirement = isBagRetirement;
			let locals_group = isBagData.locals_group.map((item) => {
				if (item.name === filterProducts[0].pharmacy) {
					return localGroupPharmacy;
				}
				return item;
			});
			validRetirement[filterProducts[0].pharmacy] = {
				info: products,
				deliveryStatus: false,
				type_retirement: null,
			};
			setBagRetirement({ ...validRetirement });
			const bagData = { ...isBagData, locals_group: [...locals_group] };
			setBagData(bagData);
			setBagTotalCart(handleBagTotal(bagData));
			setBag(products);
			setLoadingGroup((prevState) => ({
				...prevState,
				[props.name]: false,
			}));
		}
		setBagRequest(false);
	};

	const handleDeleteQuantity = async (cart, product) => {
		if (product.pbm_data) {
			Notify({
				title: `¡Advertencia!`,
				colorTitle: 'ph-main-notification-error-title',
				description: 'Tienes un convenio PBM asociado a este producto, si deseas modificar la cantidad, debes eliminar primero la adjudicacion.',
				time: 3,
				image: 'warning',
				placement: 'topRight',
			});
			return;
		}
		setBagRequest(true);
		const products = await handleDeleteQuantityProduct(cart, product);
		if (products) {
			let filterProducts = products.filter((item) => item.pharmacy === product.pharmacy);
			setLoadingGroup((prevState) => ({
				...prevState,
				[props.name]: true,
			}));
			let localGroupPharmacy;

			let userData;
			if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);

			const voucherPharmacies = handleVerifyVoucherFields();

			await Calculation(
				filterProducts,
				isLocation,
				isLoginUser.email,
				isLoginUser.token,
				voucherPharmacies,
				handleLocals(filterProducts),
				'domicilio',
				userData,
				false,
				handleCountPharmacies(),
			).then(async (response) => {
				let responseService;
				if (response?.locals_group?.length > 1) {
					const localsGroup = await BagMergeLocals(response.locals_group);
					responseService = { ...response, locals_group: [localsGroup] };
				} else responseService = response;

				let deliveryPharmacies = isBagDeliveryPharmacies;
				deliveryPharmacies[filterProducts[0].pharmacy] =
					responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
				setBagDeliveryPharmacies({ ...deliveryPharmacies });
			});

			await Calculation(
				filterProducts,
				isLocation,
				isLoginUser.email,
				isLoginUser.token,
				voucherPharmacies,
				handleLocals(filterProducts),
				'farmacia',
				userData,
				false,
				handleCountPharmacies(),
			).then(async (response) => {
				let responseService;
				if (response?.locals_group?.length > 1) {
					const localsGroup = await BagMergeLocals(response.locals_group);
					responseService = { ...response, locals_group: [localsGroup] };
				} else responseService = response;

				localGroupPharmacy = responseService.locals_group[0];
				let retirementPharmacies = isBagRetirementPharmacies;
				retirementPharmacies[filterProducts[0].pharmacy] =
					responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
				setBagRetirementPharmacies({ ...retirementPharmacies });
			});

			const validRetirement = isBagRetirement;
			let locals_group = isBagData.locals_group.map((item) => {
				if (item.name === filterProducts[0].pharmacy) {
					return localGroupPharmacy;
				}
				return item;
			});
			validRetirement[filterProducts[0].pharmacy] = {
				info: products,
				deliveryStatus: false,
				type_retirement: null,
			};
			setBagRetirement({ ...validRetirement });
			const bagData = { ...isBagData, locals_group: [...locals_group] };
			setBagData(bagData);
			setBagTotalCart(handleBagTotal(bagData));
			setBag(products);
			setLoadingGroup((prevState) => ({
				...prevState,
				[props.name]: false,
			}));
		}
		setBagRequest(false);
	};

	const handleDelete = async (cart, product) => {
		if (!!props.productsSelected && props.productsSelected.length > 0)
			handleDeleteProductFromCartElectronic(product, props.productsSelected, props.setProductsSelected);
		setBagRequest(true);
		const products = await handleDeleteProduct(cart, product);
		if (products?.length > 0) {
			let validCompleteDelete = false;
			handleElectronicVerification(products);
			let productsToDelete = [];
			let locals_group = isBagData.locals_group.map((element) => {
				if (element.name === product.pharmacy) {
					const index = element.products.findIndex((search) => {
						if (search.objectID) return search.objectID === product.objectID;
						else return search.id === product.id;
					});
					if (index !== -1) {
						element.products.splice(index, 1);
						productsToDelete = element.products;
					}
				}
				if (element.products.length === 0) {
					validCompleteDelete = true;
				}
				return element;
			});
			if (validCompleteDelete === true) {
				const indexCompleteDelete = locals_group.findIndex((search) => {
					return search.name === product.pharmacy;
				});
				if (indexCompleteDelete !== -1) {
					locals_group.splice(indexCompleteDelete, 1);
				}
			}
			if (productsToDelete.length > 0) {
				productsToDelete.forEach((item) => {
					delete item.type_retirement;
				});
				setLoadingGroup((prevState) => ({
					...prevState,
					[props.name]: true,
				}));

				let userData;
				if (isLoginUser?.email) userData = await GetUserData(isLoginUser.email);

				const voucherPharmacies = handleVerifyVoucherFields();

				await Calculation(
					productsToDelete,
					isLocation,
					isLoginUser.email,
					isLoginUser.token,
					voucherPharmacies,
					handleLocals(productsToDelete),
					'domicilio',
					userData,
					false,
					handleCountPharmacies(),
				).then(async (response) => {
					let responseService;
					if (response?.locals_group?.length > 1) {
						const localsGroup = await BagMergeLocals(response.locals_group);
						responseService = { ...response, locals_group: [localsGroup] };
					} else responseService = response;

					let deliveryPharmacies = isBagDeliveryPharmacies;
					deliveryPharmacies[productsToDelete[0].pharmacy] =
						responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
					setBagDeliveryPharmacies({ ...deliveryPharmacies });
				});
				await Calculation(
					productsToDelete,
					isLocation,
					isLoginUser.email,
					isLoginUser.token,
					voucherPharmacies,
					handleLocals(productsToDelete),
					'farmacia',
					userData,
					false,
					handleCountPharmacies(),
				).then(async (response) => {
					let responseService;
					if (response?.locals_group?.length > 1) {
						const localsGroup = await BagMergeLocals(response.locals_group);
						responseService = { ...response, locals_group: [localsGroup] };
					} else responseService = response;

					locals_group = isBagData.locals_group.map((element) => {
						if (element.name === product.pharmacy) {
							return responseService.locals_group[0];
						} else {
							return element;
						}
					});

					let retirementPharmacies = isBagRetirementPharmacies;
					retirementPharmacies[productsToDelete[0].pharmacy] =
						responseService && responseService.locals_group[0].locals.length > 0 ? responseService.locals_group[0] : false;
					setBagRetirementPharmacies({ ...retirementPharmacies });
				});
				const validRetirement = isBagRetirement;
				validRetirement[productsToDelete[0].pharmacy] = {
					info: productsToDelete,
					deliveryStatus: false,
					type_retirement: null,
				};
				setBagRetirement({ ...validRetirement });
				setLoadingGroup((prevState) => ({
					...prevState,
					[props.name]: false,
				}));
			}
			const bagData = { ...isBagData, locals_group: [...locals_group] };
			setBagData(bagData);
			const filter = BagRecipeFilter(cart);
			setBagRecipe(filter.some((item) => !item.recipe));
			setBagFilterProducts(filter);
			setBagTotalCart(handleBagTotal(bagData));
			setBag(products);
		} else {
			setBagData(null);
			setBag([]);
			setBagTotalCart(0);
			localStorage.removeItem('onDemandData');
			localStorage.setItem('ePrescriptionToken', JSON.stringify({}));
		}
		setBagRequest(false);
	};

	const handleElectronicVerification = (item) => {
		let electronicProducts = [];
		if (item.length === 0) {
			localStorage.removeItem('onDemandData');
			setGlobal(() => ({
				onDemandCart: false,
			}));
		}
		item.forEach((element) => {
			if (!element.interface?.on_demand || !item.interface?.magistral) {
				localStorage.removeItem('onDemandData');
				setGlobal(() => ({
					onDemandCart: false,
				}));
			}
			if (element.rem_product) {
				electronicProducts.push(element);
			}
		});
		if (electronicProducts.length === 0) {
			localStorage.setItem('ePrescriptionToken', JSON.stringify({}));
		}
	};

	useEffect(() => {
		if (!isCovenantsPBM) {
			servicesPBM.checkProductsPBM(props.products).then((response) => {
				if (response) {
					setGlobal({ pbmCheck: true });
				}
			});
		}
	}, [props.products, isCovenantsPBM]);

	const handleTypeRecipes = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<div className="ph-bag-products-list-global-container">
				{props?.products?.map((item, i) => (
					<div className="ph-bag-product-list-container" key={i}>
						<Row>
							<Col xs={8} sm={6} md={6} lg={6} xl={6}>
								<a className="ph-bag-product-list-image-main-container" href={`/products/${item.slug}`}
									 rel="noopener noreferrer">
									<Images
										classContent={'ph-bag-product-list-image-container'}
										classImage={'ph-bag-product-list-image'}
										webp={item.id_pharol === undefined ? item.image : `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.webp`}
										default={item.id_pharol === undefined ? item.image : `${ENV_IMG_ROUTE}/products/${ENV_COUNTRY}/600x600/${item.id_pharol}.png`}
										title={item.editable_name}
									/>
									<h4 className="ph-bag-product-quantity-image">{item.quantity}</h4>
								</a>
								<div className="ph-bag-product-type-one-container">
									{console.log(item.master_category)}
									{item.interface?.prescryption_type && item.master_category === 'Medicamentos' && (
										<div onClick={handleTypeRecipes} style={{ cursor: 'pointer' }}>
											<BagTypePrescription data={item.interface.prescryption_type} />
										</div>
									)}
									{item.interface?.bioequivalent && <BagTypePrescription data={item.interface.bioequivalent} />}
									{item.interface?.cooled && <BagTypePrescription data={item.interface.cooled} />}
								</div>
							</Col>

							<Col xs={16} sm={18} md={18} lg={18} xl={18}>
								<h3 className="ph-bag-product-list-detail-laboratory-title">
									<CountryValidator countries={['CL']} component={<>Laboratorio &nbsp;</>} />

									<span className="ph-bag-product-list-detail-laboratory-subtitle">{item.laboratory}</span>
								</h3>
								<Link to={`/products/${item.slug}-farmacias-${item.pharmacy}`}>
									<h3 className="ph-bag-product-list-detail-title">{item.editable_name}</h3>
								</Link>
								{item.interface && (
									<>
										{item.interface.on_demand && (
											<div className="ph-bag-product-list-detail-on-demand-main-container">
												<h3 className="ph-bag-product-list-detail-on-demand-title">{item.interface.on_demand.title}</h3>
												<Images
													classContent={'ph-bag-product-list-detail-on-demand-image-container'}
													classImage={'ph-bag-product-list-detail-on-demand-image'}
													webp={item.interface.on_demand.image.webp}
													jp2={item.interface.on_demand.image.jp2}
													jxr={item.interface.on_demand.image.jxr}
													default={item.interface.on_demand.image.default}
													title={item.interface.on_demand.title}
												/>
											</div>
										)}
										{item.interface.magistral && (
											<div className="ph-bag-product-list-detail-on-demand-main-container">
												<h3 className="ph-bag-product-list-detail-on-demand-title">{item.interface.magistral.title}</h3>
												<Images
													classContent={'ph-bag-product-list-detail-on-demand-image-container'}
													classImage={'ph-bag-product-list-detail-on-demand-image'}
													webp={item.interface.magistral.image.webp}
													jp2={item.interface.magistral.image.jp2}
													jxr={item.interface.magistral.image.jxr}
													default={item.interface.magistral.image.default}
													title={item.interface.magistral.title}
												/>
											</div>
										)}
									</>
								)}
								{item?.stock === 0 && (
									<h3 className="ph-bag-product-list-detail-stock-calculate-title">
										¡Ups! Sólo nos queda disponible: <span
										className="ph-bag-product-list-detail-stock-calculate-subtitle">{item.stock_available}</span>
									</h3>
								)}
								<Promotions cartProducts={true} detail={item} />
								<div className="ph-bag-product-list-detail-price-title-container">
									{/*<h3 className="ph-bag-product-list-detail-price-title">Precio unitario:</h3>*/}
									<Price cartProducts={true} detail={item} />

									{item.interface && (
										<>

											{!item.interface.on_demand && (
												<div className="ph-bag-product-list-button-add-delete-container"
														 tour-cart="tourCart__stepSeven">
													{item.quantity > 1 ? (
														<Button
															disabled={isBagRequest}
															className="ph-bag-product-list-button-add-quantity"
															onClick={() => handleDeleteQuantity(JSON.parse(localStorage.getItem('cart')), item)}
														>
															<MinusOutlined />
															{/*<LeftOutlined/>*/}

														</Button>
													) : (
														<Button
															disabled={isBagRequest}
															className="ph-bag-product-list-delete-button"
															onClick={() => handleDelete(JSON.parse(localStorage.getItem('cart')), item)}
															icon={<DeleteOutlined/>}
														/>)}
													<Input className="ph-bag-product-list-button-quantity-input"
																 placeholder={item.quantity} disabled/>
													<Button
														disabled={item?.stock === 0 || isBagRequest}
														className="ph-bag-product-list-button-delete-quantity"
														onClick={() => handleAddQuantity(JSON.parse(localStorage.getItem('cart')), item, 1)}
													>
														<PlusOutlined />
														{/*<RightOutlined/>*/}

													</Button>
												</div>
											)}
										</>
									)}
								</div>
								{item.quantity >= 1 && (
									// <h3 className="ph-bag-product-list-detail-price-title">
									// 	Precio por <span className="ph-bag-product-list-detail-price-quantity">({item.quantity})</span> unidades:{' '}
									// 	<span className="ph-bag-product-list-detail-price-subtitle">{formatPrice(item.sub_total)}</span>
									// 	{item.interface && <>{item.interface.promotions && <span className="ph-bag-product-list-detail-price-subtitle-asterisk">**</span>}</>}
									// </h3>

									// New version
									<h3 className="ph-bag-product-list-detail-price-title">
										<span className="ph-bag-product-list-detail-price-quantity">({item.quantity})</span>

										<span
											className="ph-bag-product-list-detail-price-subtitle">{formatPrice(item.sub_total - Math.abs(item.valid_data_payment.discount || 0))} </span>
									</h3>
								)}
								{/*{item.interface && item.quantity > 1 && (*/}
								{/*	<>*/}
								{/*		{item.interface.promotions && (*/}
								{/*			<>*/}
								{/*				<span className="ph-bag-product-list-detail-price-promotion-asterisk">**</span>*/}
								{/*				<span className="ph-bag-product-list-detail-promotion-subtitle">No incluye la promoción asociada.</span>*/}
								{/*			</>*/}
								{/*		)}*/}
								{/*	</>*/}
								{/*)}*/}
								{item.interface_pbm && <>{item.interface_pbm.length > 0 &&
									<BagPBM item={item} cartForm={props.cartForm} />}</>}
								{/* PARCHE FABO */}
								{/* {item.alternatives && item.alternatives.length > 0 && (
									<>
										<BagAlternatives cartForm={props.cartForm} alternatives={item.alternatives} carouselItems={[2, 2, 2, 1, 1]} />
									</>
								)} */}
								{/*<Button*/}
								{/*	disabled={isBagRequest}*/}
								{/*	className="ph-bag-product-list-delete-button"*/}
								{/*	onClick={() => handleDelete(JSON.parse(localStorage.getItem('cart')), item)}*/}
								{/*	icon={<DeleteFilled />}*/}
								{/*/>*/}

								{/*{item.interface && (*/}
								{/*	<>*/}
								{/*		{!item.interface.on_demand && (*/}
								{/*			<div className="ph-bag-product-list-button-add-delete-container" tour-cart="tourCart__stepSeven">*/}
								{/*				<Button*/}
								{/*					disabled={isBagRequest}*/}
								{/*					className="ph-bag-product-list-button-add-quantity"*/}
								{/*					onClick={() => handleDeleteQuantity(JSON.parse(localStorage.getItem('cart')), item)}*/}
								{/*				>*/}
								{/*					<LeftOutlined />*/}
								{/*				</Button>*/}
								{/*				<Input className="ph-bag-product-list-button-quantity-input" placeholder={item.quantity} disabled />*/}
								{/*				<Button*/}
								{/*					disabled={item?.stock === 0 || isBagRequest}*/}
								{/*					className="ph-bag-product-list-button-delete-quantity"*/}
								{/*					onClick={() => handleAddQuantity(JSON.parse(localStorage.getItem('cart')), item, 1)}*/}
								{/*				>*/}
								{/*					<RightOutlined />*/}
								{/*				</Button>*/}
								{/*			</div>*/}
								{/*		)}*/}
								{/*	</>*/}
								{/*)}*/}
							</Col>
						</Row>
					</div>
				))}
			</div>
			<ModalTypeRecipes isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
		</>
	);
}
